import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { getToast } from "modules/common/toast";
import { AuthWizardContext } from "context/Context";
import axios from "axios";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import placeholder1 from "../../assets/images/placeholder/placeholder1.jpg";
import { Modal as ConfirmDialog } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getAdminDetails } from "utils/adminUtils";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";

const AdminNavbar = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const { dispatch, user } = useContext(AuthWizardContext);

  const admin = getAdminDetails();

  console.log("user", admin);

  const getComponentName = () => {
    switch (true) {
      case location.pathname === "/":
        return "DASHBOARD";
      case location.pathname === "/dashboard":
        return "DASHBOARD";
      case location.pathname === "/products":
        return "PRODUCTS";
      case location.pathname === "/products/add":
        return {
          name: "PRODUCTS",
          child: "ADD",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname.includes("products/edit"):
        return {
          name: "PRODUCTS",
          child: "EDIT",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname === "/vendors":
        return "VENDORS";
      case location.pathname === "/vendors/add":
        return {
          name: "VENDOR",
          child: "ADD",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname.includes("vendors/edit"):
        return {
          name: "VENDOR",
          child: "EDIT",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname === "/customers":
        return "CUSTOMERS";
      case location.pathname === "/customers/add":
        return {
          name: "CUSTOMER",
          child: "ADD",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname.includes("customers/edit"):
        return {
          name: "CUSTOMER",
          child: "EDIT",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname === "/coupon":
        return "COUPON";
      case location.pathname === "/coupon/add":
        return {
          name: "COUPON",
          child: "ADD",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname.includes("coupon/edit"):
        return {
          name: "COUPON",
          child: "EDIT",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname === "/deleted-products":
        return "DELETED PRODUCTS";
        

      case location.pathname === "/orders":
        return "ALL ORDERS";
      case location.pathname === "/new-orders":
        return "NEW ORDERS";
      case location.pathname === "/international-orders":
        return "INTERNATIONAL ORDERS";
      // case location.pathname === "/customers/add":
      //   return {
      //     name: "CUSTOMERS",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("customers/edit"):
      //   return {
      //     name: "CUSTOMERS",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/players":
      //   return "PLAYERS";
      // case location.pathname === "/players/add":
      //   return {
      //     name: "PLAYERS",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("players/edit"):
      //   return {
      //     name: "PLAYERS",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };

      // case location.pathname === "/agents":
      //   return "AGENTS";
      // case location.pathname === "/agents/add":
      //   return {
      //     name: "AGENTS",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("agents/edit"):
      //   return {
      //     name: "AGENTS",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/last-users":
      //   return "Last Active Users ";
      case location.pathname === "/settings/main-category":
        return {
          name: "SETTINGS",
          child: "CATEGORY",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname === "/settings/main-category/add":
        return {
          name: "CATEGORY",
          child: "ADD",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname.includes("main-category/edit"):
        return {
          name: "CATEGORY",
          child: "EDIT",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname === "/settings/sub-category":
        return {
          name: "SETTINGS",
          child: "SUB CATEGORY",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname === "/settings/sub-category/add":
        return {
          name: "SUB CATEGORY",
          child: "ADD",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname.includes("sub-category/edit"):
        return {
          name: "SUB CATEGORY",
          child: "EDIT",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname === "/settings/sizes":
        return {
          name: "SETTINGS",
          child: "SIZE",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname === "/settings/sizes/add":
        return {
          name: "SIZE",
          child: "ADD",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname.includes("sizes/edit"):
        return {
          name: "SIZE",
          child: "EDIT",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname === "/settings/banner":
        return {
          name: "SETTINGS",
          child: "BANNER",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname === "/settings/banner/add":
        return {
          name: "BANNER",
          child: "ADD",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname.includes("banner/edit"):
        return {
          name: "BANNER",
          child: "EDIT",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname === "/settings/color":
        return {
          name: "SETTINGS",
          child: "COLOR",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname === "/settings/color/add":
        return {
          name: "COLOR",
          child: "ADD",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname.includes("color/edit"):
        return {
          name: "COLOR",
          child: "EDIT",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname === "/settings/brand":
        return {
          name: "SETTINGS",
          child: "BRAND",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      case location.pathname === "/settings/brand/add":
        return {
          name: "BRAND",
          child: "ADD",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname.includes("brand/edit"):
        return {
          name: "BRAND",
          child: "EDIT",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };



        case location.pathname === "/deleted-products-admin":
          return {
            name: "REMOVED",
            child: "PRODUCTS",
            icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
          };
      // case location.pathname === "/settings/nationality":
      //   return {
      //     name: "SETTINGS",
      //     child: "NATIONALITY",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/settings/nationality/add":
      //   return {
      //     name: "NATIONALITY",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("nationality/edit"):
      //   return {
      //     name: "NATIONALITY",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/settings/city":
      //   return {
      //     name: "SETTINGS",
      //     child: "CITY",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/settings/city/add":
      //   return {
      //     name: "CITY",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("city/edit"):
      //   return {
      //     name: "CITY",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/settings/system":
      //   return {
      //     name: "SETTINGS",
      //     child: "SYSTEM",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/settings/system/add":
      //   return {
      //     name: "SYSTEM SETTINGS",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("system/edit"):
      //   return {
      //     name: "SYSTEM SETTINGS",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/settings/facility":
      //   return {
      //     name: "SETTINGS",
      //     child: "FACILITY",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/settings/facility/add":
      //   return {
      //     name: "FACILITY",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("facility/edit"):
      //   return {
      //     name: "FACILITY",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/field-feature":
      //   return {
      //     name: "FIELD FEATURE",
      //     child: "",
      //   };
      // case location.pathname === "/field-list":
      //   return {
      //     name: "ALL FIELDS",
      //     child: "",
      //   };
      // case location.pathname === "/verification-pending-field-list":
      //   return {
      //     name: "VERIFICATION PENDING FIELDS",
      //     child: "",
      //   };
      // case location.pathname === "/rejected-field-list":
      //   return {
      //     name: "REJECTED & DELETED FIELDS",
      //     child: "",
      //   };
      // case location.pathname === "/field-feature/add":
      //   return {
      //     name: "FIELD FEATURE",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("field-feature/edit"):
      //   return {
      //     name: "FIELD FEATURE",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/roles":
      //   return {
      //     name: "ROLES",
      //     child: "",
      //   };
      // case location.pathname === "/roles/add":
      //   return {
      //     name: "ROLES",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("roles/edit"):
      //   return {
      //     name: "ROLES",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/settings/promo-code":
      //   return {
      //     name: "PROMO CODE",
      //     child: "",
      //   };
      // case location.pathname === "/settings/promo-code/add":
      //   return {
      //     name: "PROMO CODE",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/ads":
      //   return {
      //     name: "ADS",
      //     child: "",
      //   };
      // case location.pathname === "/ads/add":
      //   return {
      //     name: "ADS",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/tutorials":
      //   return {
      //     name: "TUTORIALS",
      //     child: "",
      //   };
      // case location.pathname === "/tutorials/add":
      //   return {
      //     name: "TUTORIALS",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("tutorials/edit"):
      //   return {
      //     name: "TUTORIALS",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/reports":
      //   return {
      //     name: "REPORTS",
      //     child: "",
      //   };
      // case location.pathname === "/reports/customer-report":
      //   return {
      //     name: "REPORTS",
      //     child: "CUSTOMER REPORT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/reports/agent-report":
      //   return {
      //     name: "REPORTS",
      //     child: "AGENT REPORT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/reports/payment-report":
      //   return {
      //     name: "REPORTS",
      //     child: "PAYMENT REPORT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/settings/match":
      //   return {
      //     name: "SETTINGS",
      //     child: "MATCH",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("match/edit"):
      //   return {
      //     name: "MATCH SETTINGS",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/settings/app-page":
      //   return {
      //     name: "SETTINGS",
      //     child: "CONTENT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("app-page/edit"):
      //   return {
      //     name: "CONTENT",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/reports/customer-transactions-report":
      //   return {
      //     name: "REPORTS",
      //     child: "CUSTOMER TRANSACTION",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/payout/summary":
      //   return {
      //     name: "PAYOUT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //     child: "SUMMARY",
      //   };
      // case location.pathname.includes("payout/history"):
      //   return {
      //     name: "PAYOUT",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //     child: "HISTORY",
      //   };
      case location.pathname === "/payout/list":
        return {
          name: "PAYOUT",
          child: "LIST",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };

      case location.pathname === "/payment/summary":
        return {
          name: "PAYMENT",
          child: "SUMMARY",
          icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
        };
      // case location.pathname === "/payout/list/add":
      //   return {
      //     name: "PAYOUT",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("payout/list/edit"):
      //   return {
      //     name: "PAYOUT",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };

      // case location.pathname === "/reports/agent-transactions-report":
      //   return {
      //     name: "REPORTS",
      //     child: "AGENT TRANSACTION",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname === "/subscription-plans":
      //   return {
      //     name: "SUBSCRIPTION PLANS",
      //     child: "",
      //   };
      // case location.pathname === "/subscription-plans/add":
      //   return {
      //     name: "SUBSCRIPTION PLANS",
      //     child: "ADD",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      // case location.pathname.includes("subscription-plans/edit"):
      //   return {
      //     name: "SUBSCRIPTION PLANS",
      //     child: "EDIT",
      //     icon: <MdOutlineDoubleArrow size={28} className="pb-1" />,
      //   };
      default:
        return "Page Not Found";
    }
  };

  const handleLogout = () => {
    ConfirmDialog.confirm({
      title: "Are you sure you want to logout?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes, Logout",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        localStorage.removeItem("LOGGED_USER");
        localStorage.removeItem("token");
        dispatch({ type: "DELETE" });
        navigate("/authenticate/login");
      },
    });
  };

  return (
    <Navbar bg="light" expand="lg" className="p-0">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {typeof getComponentName() === "string"
              ? getComponentName()
              : getComponentName().name}
            {getComponentName().icon}
            {getComponentName().child}
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto" navbar>
            <Nav.Item>
              <Nav.Link className="m-0 p-0">
                <div className="d-flex align-items-center gap-1 h-100">
                  <span className="no-icon">
                    <Image
                      src={
                        user?.avatar ? IMAGE_URL + user?.avatar : placeholder1
                      }
                      alt="?"
                      roundedCircle
                      height={30}
                      width={30}
                    />
                  </span>
                  <span className="fw-bold text-muted">{user?.first_name}</span>
                  <Button
                    className="rounded-0"
                    variant="light"
                    size="sm"
                    onClick={handleLogout}
                    title="Logout"
                  >
                    <span className="no-icon">
                      <AiOutlineLogout size={22} className="fw-bold" />
                    </span>
                  </Button>
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AdminNavbar;
