import { AuthWizardContext } from "context/Context";
import React, { useContext } from "react";
import { Navigate } from "react-router";

const ValidateAuth = ({ children }) => {
  let { user } = useContext(AuthWizardContext);
  return !user ? <Navigate to="/authenticate/login" /> : children;
};

export default ValidateAuth;
