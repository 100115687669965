import Loading from "components/common/LoadingScreen/Loading";
import { API } from "constants/apis";
import { AuthWizardContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import React, { useContext, useEffect, useReducer, useState } from "react";
import authReducer from "reducers/authReducer";

const AuthenticationProviders = ({ children }) => {
  const [loading, setloading] = useState(true);
  const [user, dispatch] = useReducer(
    authReducer,
    useContext(AuthWizardContext)
  );

  const getUserInfo = async () => {
    let userData = JSON.parse(localStorage.getItem("LOGGED_USER"));
    let data = null;
    if (userData) {
      dispatch({
        type: "UPDATE",
        payload: userData,
      });
    }
    setloading(false);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div>
      <AuthWizardContext.Provider
        value={{
          user,
          dispatch,
        }}
      >
        {!loading ? children : <Loading />}
      </AuthWizardContext.Provider>
    </div>
  );
};

export default AuthenticationProviders;
