

import React from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import { get } from "helpers/apiConfig";

const SelectCountry = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
}) => {
  const loadOptions = async (inputValue) => {
    try {
      let response = await get("/admin/regions", {});
      let fetchData = response?.data?.regions || [];

      // Filter based on inputValue
      return fetchData
        .filter((item) =>
          item?.name?.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((item) => ({
          label: item?.name,
          value: keyName ? item[keyName] : item._id,
          code: item?.code,
        }));
    } catch (error) {
      console.error("Error fetching countries:", error);
      return [];
    }
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      isClearable
      name={name ?? "country"}
      value={value}
      placeholder={placeholder ?? "Select Country"}
      onChange={handleFieldChange}
      className={error && "form-control ps-0 py-0 is-invalid"}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          height: 42,
          minHeight: 42,
          ...style,
        }),
      }}
    />
  );
};

SelectCountry.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectCountry;
