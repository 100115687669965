// import { Modal } from "bootstrap";
import axios from "axios";
import SelectAgent from "components/common/Form/SelectAgent";
import Loading from "components/common/LoadingScreen/Loading";
import FilterButton from "components/common/filter/FilterButton";
import { DeleteContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import removeRefData from "helpers/removeRefData";
import TableHeaderCustom from "modules/common/TableHeaderCustom";
import TablePageination from "modules/common/TablePageination";
import { getToast } from "modules/common/toast";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Button, Card, Form, Table, Modal } from "react-bootstrap";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { Outlet, useNavigate } from "react-router";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";

const Payout = () => {
  const [payoutList, setPayoutList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const [showBankModal, setShowBankModal] = useState(null);
  const [showViewModal, setShowViewModal] = useState(null);

  const { dispatch } = useContext(DeleteContext);
  const filterItems = queryParams.get("filters");
  // const commonFilterOperators = [
  //   { name: "Equal", value: "=" },
  //   { name: "LIKE", value: "LIKE" },
  // ];
  // const [filters] = useState({
  //   user_id: {
  //     label: "User",
  //     operators: [{ name: "Equal", value: "=" }],
  //     Element: (item) =>
  //       item && item.operator === "=" ? (
  //         <div className="w-100">
  //           <SelectAgent
  //             value={item.value_ref}
  //             name={item.name}
  //             handleFieldChange={item.handleFieldChange}
  //           />
  //         </div>
  //       ) : (
  //         "STRING"
  //       ),
  //   },
  //   date: {
  //     label: "Date",
  //     operators: [{ name: "Equal", value: "=" }],
  //     Element: (item) =>
  //       item && item.operator === "=" ? (
  //         <div className="w-100">
  //           <Form.Control
  //             className="custom-input"
  //             placeholder="Enter Date"
  //             name={item.name}
  //             type="date"
  //             onFocus={(e) => (e.target.type = "date")}
  //             onBlur={(e) => (e.target.type = "text")}
  //             value={item.value}
  //             onChange={item.handleFieldChange}
  //           />
  //         </div>
  //       ) : (
  //         "STRING"
  //       ),
  //   },
  // });

  // const handleDelete = (id) => {
  //   dispatch({
  //     type: "CONFIG",
  //     payload: {
  //       target: id,
  //       url: "pay-outs/" + id,
  //       title: "Delete Payout",
  //       message: "Are you sure you want to delete this payout?",
  //       onSuccess: () => {
  //         const dataWithoutDeletedItem = payoutList.data.filter(
  //           (payout) => payout.id !== id
  //         );

  //         if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
  //           // if data length < 1 and page number > 1 then redirect to previous page
  //           navigate("/payout?" + createSearchParams({ page: pageNumber - 1 }));
  //         } else {
  //           setPayoutList((previous) => ({
  //             ...previous,
  //             data: dataWithoutDeletedItem,
  //           }));
  //           // reset deleteContext
  //           dispatch({
  //             type: "RESET",
  //           });
  //         }
  //       },
  //     },
  //   });
  // };

  const getData = async (page = 1) => {
    setIsLoading(true);
    let userId;
    let date;

    const data = await apiCall({
      url: "/orders/payment-summary",
      // url: "pay-outs",
      params: {
        // page: page,
        // user_id: userId,
        // date: date,
      },
    });

    console.log(data);

    setPayoutList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, filterItems]);

  const handlViewBankDetails = (id) => {
    // console.log(id);

    setShowBankModal(id);
  };

  const handlViewDetails = (payout) => {
    setShowViewModal(payout);
  };



  return (
    <>
      <Outlet context={[getData]} />
      {!isLoading ? (
        <Card className="rounded-0 border-0 h-100 w-100 d-inline-block overflow-auto">
          {/* <div className="d-flex justify-content-between">
              <span>
                <FilterButton
                  className="me-1 d-flex justify-content-end border"
                  filters={filters}
                  url="payout/list"
                  variant="light"
                  showText
                />
              </span>
            </div> */}
          {payoutList && Object.keys(payoutList).length > 0 && (
            <Card className="mb-3 p-2 shadow-sm">
              <Card.Header className="bg-primary text-white">
                <h5 className="mb-0">Payout Summary</h5>
              </Card.Header>
              <Card.Body>
                {/* Fourth Row - New Values from payoutList */}
                {payoutList && Object.keys(payoutList).length > 0 && (
                  <>
                    <div className="row mb-2">
                      <div className="col-md-4">
                        <strong>Total Orders:</strong> {payoutList?.totalOrders}
                      </div>

                      <div className="col-md-4">
                        <strong>Total Payment:</strong> ₹
                        {payoutList?.totalPayment?.toFixed(2)}
                      </div>
                      <div className="col-md-4">
                        <strong>Pending Admin Fee:</strong> ₹
                        {payoutList?.AdminFee?.toFixed(2)}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4">
                        <strong>Total Confirmed Orders:</strong> ₹
                        {payoutList?.totalConfirmedOrders?.toFixed(2)}
                      </div>

                      <div className="col-md-4">
                        <strong>Total Confirmed Payment:</strong> ₹
                        {payoutList?.totalConfirmed?.toFixed(2)}
                      </div>
                      <div className="col-md-4">
                        <strong>Confirmed Admin Fee:</strong> ₹
                        {payoutList?.confirmedAdminFee?.toFixed(2)}
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-4">
                        <strong>Total Net Amount:</strong> ₹
                        {payoutList?.totalNetAmount?.toFixed(2)}
                      </div>
                      <div className="col-md-4">
                        <strong>Total Paid Amount</strong> ₹
                        {payoutList?.totalForAdmin?.toFixed(2)}
                      </div>
                      <div className="col-md-4">
                        <strong>Total UnPaid Amount</strong> ₹
                        {payoutList?.totalBalance?.toFixed(2)}
                      </div>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          )}

          <Card.Body className="p-0 h-100 d-flex flex-column border-top">
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Vendor</th>
                  <th>Total Orders</th>
                  <th>Confirmed Orders</th>
                  <th>Total Payment</th>
                  <th>Confirmed Payment</th>
                  <th>Total Commission</th>
                  <th>Confirmed Commission</th>
                  <th>Final Amount</th>
                  <th>Paid Amount</th>
                  <th>Unpaid Amount</th>
                  {/* <th>Last Payment Date</th>
                  <th>Bank Details</th>
                  <th>Add Payout</th> */}
                </tr>
              </thead>
              <tbody>
                {Object.keys(payoutList).length > 0 &&
                Object.keys(payoutList.summary).length > 0 ? (
                  payoutList.summary.map((payout, key) => {
                    return (
                      <tr key={key}>
                        {/* {console.log(payout)} */}
                        <td width={"5%"}>{key + 1}</td>
                        <td>{payout?.vendorName}</td>
                        <td>{payout?.totalOrders}</td>
                        <td>{payout?.totalConfirmedOrders}</td>
                        <td>{payout?.totalPayment}</td>
                        <td>{payout?.confirmedAmount}</td>
                        <td>{payout?.commissionAmount}</td>
                        <td>{payout?.confirmedCommissionAmount}</td>
                        <td>{payout?.finalAmount}</td>
                        <td>{payout?.paidAmount}</td>
                        <td>{payout?.pendingAmount}</td>
                        {/* <td></td>

                        <td className="text-center">
                          <a
                            className="field-list"
                            onClick={() => handlViewBankDetails(payout?.id)}
                            href="#"
                          >
                            View
                          </a>
                        </td>

                        <td className="text-center">
                          <Button
                            disabled
                            className="rounded-0"
                            size="sm"
                            as={Link}
                            to={`add?${createSearchParams({
                              ...allQueryParams,
                              user_id: payout?.id,
                            })}`}
                          >
                            <MdAdd size={18} />
                          </Button>
                        </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <span className="d-flex justify-content-center">
                        No Data Found &#x1F609;
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Card.Footer className="border p-0 mt-auto">
              <TablePageination
                data={payoutList}
                url="/payout/list"
                search={allQueryParams}
              />
            </Card.Footer>
          </Card.Body>
        </Card>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Payout;

{
  /* <BankDetailsModal
show={showBankModal != null ? true : false}
handleClose={() => setShowBankModal(null)}
userId={showBankModal}
/>
<PayoutDetailsModal
bankModal={showBankModal != null ? true : false}
handlViewBankDetails={handlViewBankDetails}
show={showViewModal != null ? true : false}
handleClose={() => setShowViewModal(null)}
data={showViewModal}
/> */
}

const BankDetailsModal = ({ show, handleClose, userId }) => {
  const [showModal, setShowModal] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);

  const getData = () => {
    axios({
      method: "GET",
      url: `user/bank-accounts?user_id=${userId}`,
      data: {},
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(userId, res?.data?.data?.data);
        setBankDetails(res?.data?.data?.data);
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    getData();
  }, [show]);

  const handleAddBankModal = () => {
    setShowModal(true);
  };

  return (
    <div>
      <Modal className="field-modal-container" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
          <span className="">
            <Button
              onClick={() => handleAddBankModal()}
              className="rounded-0"
              size="sm"
            >
              <MdAdd size={18} />
            </Button>
          </span>
        </Modal.Header>
        <Modal.Body>
          <table className="field-modal-table table table-bordered">
            <tbody>
              <tr>
                <th>#</th>
                <th>Id</th>
                <th>Bank Name</th>
                <th>Account Number</th>
                <th>Account Holder Name</th>
                <th>Branch Name</th>
              </tr>
              {bankDetails?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.id}</td>
                  <td>{item?.bank_name}</td>
                  <td>{item?.account_number}</td>
                  <td>{item?.account_holder_name}</td>
                  <td>{item?.branch_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ height: 30 }} />
        </Modal.Body>
      </Modal>
      <AddBank
        show={showModal}
        handleRefresh={getData}
        handleClose={() => setShowModal(false)}
        id={userId}
      />
    </div>
  );
};

const AddBank = ({ show, handleClose, id, handleRefresh }) => {
  const [bankDetails, setBankDetails] = useState({
    user_id: id,
    bank_name: "",
    account_number: "",
    account_holder_name: "",
    branch_name: "",
  });
  const [formError, setFormError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "account_number") {
      if (!/^\d*$/.test(value)) {
        setFormError({ account_number: "Please enter numbers only." });
        return;
      } else {
        setFormError({ account_number: "" });
      }
    }
    setBankDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    axios({
      method: "POST",
      url: "user/add-bank-details",
      data: { ...bankDetails, user_id: id },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          setBankDetails({
            user_id: id,
            bank_name: "",
            account_number: "",
            account_holder_name: "",
            branch_name: "",
          });
          getToast(res.data.message, "success");
          handleRefresh();
          handleClose();
        }
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data.message) {
          getToast(error.response.data.message, "error");

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          getToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
      });
  };

  return (
    <Modal className="field-modal-container" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Bank Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="bankName">
            <Form.Label>Bank Name</Form.Label>
            <Form.Control
              type="text"
              name="bank_name"
              value={bankDetails.bank_name}
              onChange={handleChange}
              isInvalid={!!formError.bank_name}
            />
            <Form.Control.Feedback type="invalid">
              {formError.bank_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="accountNumber">
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              type="text"
              name="account_number"
              value={bankDetails.account_number}
              onChange={handleChange}
              isInvalid={!!formError.account_number}
            />
            <Form.Control.Feedback type="invalid">
              {formError.account_number}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="accountHolderName">
            <Form.Label>Account Holder Name</Form.Label>
            <Form.Control
              type="text"
              name="account_holder_name"
              value={bankDetails.account_holder_name}
              onChange={handleChange}
              isInvalid={!!formError.account_holder_name}
            />
            <Form.Control.Feedback type="invalid">
              {formError.account_holder_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="branchName">
            <Form.Label>Branch Name</Form.Label>
            <Form.Control
              type="text"
              name="branch_name"
              value={bankDetails.branch_name}
              onChange={handleChange}
              isInvalid={!!formError.branch_name}
            />
            <Form.Control.Feedback type="invalid">
              {formError.branch_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Button className="mt-3" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

/* -------------------------------------------------------------------------- */
/*                             View Details Modal                             */
/* -------------------------------------------------------------------------- */

const PayoutDetailsModal = ({
  show,
  handleClose,
  data,
  handlViewBankDetails,
  bankModal,
}) => {
  const id = data?.id;

  const [payoutHistoryModal, setPayoutHistoryModal] = useState(false);

  const handelPayoutHistory = () => {
    setPayoutHistoryModal(true);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Modal
        className="field-modal-container"
        style={payoutHistoryModal || bankModal ? { opacity: 0 } : {}}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="field-modal-table table table-bordered">
            <tbody>
              <tr>
                <th width={"50%"}>Name</th>
                <td>{data?.name}</td>
              </tr>

              <tr>
                <th>Phone</th>
                <td>{data?.phone}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{data?.email}</td>
              </tr>

              <tr>
                <th>Total Amount</th>
                <td>{data?.total_amount}</td>
              </tr>
              <tr>
                <th>Paid Amount</th>
                <td>{data?.paid_amount}</td>
              </tr>
              <tr>
                <th>Commission Amount</th>
                <td>{data?.commission_amount}</td>
              </tr>

              <tr>
                <th>Bank Details</th>
                <td>
                  <a
                    className="field-list"
                    onClick={() => {
                      handlViewBankDetails(data?.id);
                      // handleClose();
                    }}
                    href="#"
                  >
                    View
                  </a>
                </td>
              </tr>
              <tr>
                <th>transactions</th>

                <td>
                  {/* <a
                    className="field-list"
                    onClick={() => {
                      handelPayoutHistory();
                      // handleClose();
                    }}
                    href="#"
                  >
                    
                  </a> */}

                  <button
                    style={{
                      border: "none",
                      padding: 0,
                      backgroundColor: "transparent",
                      textDecorationLine: "underline",
                    }}
                    // to={{
                    //   pathname: "/payout/history",
                    //   state: { id: data?.id },
                    // }}
                    onClick={() =>
                      navigate("/payout/history", { state: { id: data?.id } })
                    }
                    // className="field-list"
                    href="#"
                  >
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ height: 30 }} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
