import React, { useState } from "react";
import { Card, Form, InputGroup, Button, Spinner } from "react-bootstrap";
import { FaEnvelope, FaPhone, FaLock, FaEyeSlash, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { getToast } from "modules/common/toast";
import { post } from "helpers/apiConfig";
import { put } from "helpers/apiConfig";

const ForgotPasswordForm = () => {
  const [step, setStep] = useState(1); // Step 1: Enter Email/Phone, Step 2: Enter OTP, Step 3: Reset Password
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
    newPassword: "",
  });

  const [user, setUser] = useState(null);
  const [resetToken, setResetToken] = useState(null);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Step 1: Send OTP
  const handleSendOTP = async () => {
    setIsLoading(true);
    try {
      console.log("formData", formData);

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData?.email?.toLowerCase())) {
        setIsLoading(false);
        getToast("Please enter a valid email address.", "error");
        return;
      }
      const response = await post("/vendor/password/forgot", {
        email: formData?.email,
      });
      if (response.data.success) {
        console.log("=>>>", response?.data);
        getToast("OTP sent successfully!", "success");
        setResetToken(response?.data?.resetToken);
        setStep(2);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
      getToast("Failed to send OTP. Try again.", "error");
    }
  };

  // Step 2: Verify OTP
  const handleVerifyOTP = async () => {
    setIsLoading(true);
    try {
      const response = await put("/vendor/password/reset", {
        token: resetToken,
        otp: formData?.otp,
      });

      if (response.data?.success) {
        localStorage.setItem("token", response.data.token);
        setUser(response.data.user);
        getToast("OTP verified successfully!", "success");
        setStep(3);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      getToast("Invalid OTP. Try again.", "error");
    }
  };

  // Step 3: Reset Password
  const handleResetPassword = async () => {
    setIsLoading(true);

    try {
      const response = await put("/vendor/password/update", {
        password: formData?.newPassword,
      });

      if (response.data.success) {
        getToast("Password reset successfully!", "success");
        navigate("/authenticate/login");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      getToast("Failed to reset password. Try again.", "error");
    }
  };

  return (
    <Card
      className="shadow form-container p-4"
      style={{ maxWidth: 500, margin: "auto" }}
    >
      <h3 className="text-center font-weight-bold mb-4">Forgot Password</h3>

      {/* Step 1: Enter Email/Phone */}
      {step === 1 && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <InputGroup className="border rounded" style={{ borderWidth: 1 }}>
              <InputGroup.Text className="border-0 bg-transparent">
                <FaEnvelope />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleFieldChange}
              />
            </InputGroup>
          </Form.Group>

          <Button
            onClick={handleSendOTP}
            disabled={isLoading}
            className="w-100"
            style={{
              backgroundColor: "#9D2233",
              borderColor: "#9D2233",
            }}
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : "Send OTP"}
          </Button>
        </>
      )}

      {/* Step 2: Enter OTP */}
      {step === 2 && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Enter OTP</Form.Label>
            <InputGroup className="border rounded" style={{ borderWidth: 1 }}>
              <Form.Control
                type="text"
                placeholder="Enter the OTP"
                name="otp"
                value={formData.otp}
                onChange={handleFieldChange}
              />
            </InputGroup>
          </Form.Group>

          <Button
            onClick={handleVerifyOTP}
            disabled={isLoading}
            className="w-100"
            style={{
              backgroundColor: "#9D2233",
              borderColor: "#9D2233",
            }}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Verify OTP"
            )}
          </Button>
        </>
      )}

      {/* Step 3: Reset Password */}
      {step === 3 && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>New Password</Form.Label>
            <InputGroup className="border rounded" style={{ borderWidth: 1 }}>
              <InputGroup.Text className="border-0 bg-transparent">
                <FaLock />
              </InputGroup.Text>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Enter new password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleFieldChange}
              />
              <InputGroup.Text
                className="border-0 bg-transparent"
                style={{ cursor: "pointer" }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Button
            onClick={handleResetPassword}
            disabled={isLoading}
            className="w-100"
            style={{
              backgroundColor: "#9D2233",
              borderColor: "#9D2233",
            }}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Reset Password"
            )}
          </Button>
        </>
      )}

      <div className="text-center mt-3">
        <Link to="/authenticate/login" className="text-muted">
          Back to Login
        </Link>
      </div>
    </Card>
  );
};

export default ForgotPasswordForm;
