import SelectAgent from "components/common/Form/SelectAgent";
import Loading from "components/common/LoadingScreen/Loading";
import FilterButton from "components/common/filter/FilterButton";
import { apiCall } from "helpers/apiCalls";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import removeRefData from "helpers/removeRefData";
import TablePageination from "modules/common/TablePageination";
import React, { useEffect, useState } from "react";
import { Button, Card, Image, Table } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { Outlet } from "react-router";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";

const PayoutSummary = () => {
  const [payoutSummary, setPayoutSummary] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const [filters] = useState({
    id: {
      label: "Agent Name",
      operators: [{ name: "Equal", value: "=" }],
      Element: (item) =>
        item && item.operator === "=" ? (
          <div className="w-100">
            <SelectAgent
              value={item.value_ref}
              name={item.name}
              handleFieldChange={item.handleFieldChange}
            />
          </div>
        ) : (
          "STRING"
        ),
    },
  });

  const getData = async (page = 1) => {
    setIsLoading(true);
    // let agentId;

    // let filterActive = filterItems
    //   ? removeRefData(createFilterArray(filterItems), "array")
    //   : null;

    // filterActive?.map((item) => {
    //   if (item.column === "id") {
    //     agentId = item.value;
    //   }
    // });

    const data = await apiCall({
      url: "/orders/payment-summary/vendor/",
      params: {
        // page: page,
        // id: agentId ?? null,
      },
    });

    console.log(data);

    setPayoutSummary(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, filterItems]);


    useEffect(() => {
      const handleKeyDown = (e) => {
        console.log(e.key);
      };
    
      window.addEventListener("keydown", handleKeyDown);
    
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, []);
  return (
    <>
      <Outlet context={[getData]} />
      {!isLoading ? (
        <Card className="rounded-0 border-0 h-100 w-100 d-inline-block overflow-auto">
          <Card.Header className="p-2">
            {/* <div className="d-flex justify-content-between">
              <span>
                <FilterButton
                  showText={true}
                  className="me-1 d-flex justify-content-end border"
                  filters={filters}
                  url="payout/summary"
                  variant="light"
                />
              </span>
            </div> */}

            {/* Summary Section */}
            {payoutSummary && Object.keys(payoutSummary).length > 0 && (
              <Card className="mb-3 shadow-sm">
                <Card.Header className="bg-primary text-white">
                  <h5 className="mb-0">Payout Summary</h5>
                </Card.Header>
                <Card.Body>
                  <div className="row mb-2">
                    <div className="col-md-3">
                      <strong>Total Orders:</strong>{" "}
                      {payoutSummary?.totalOrders}
                    </div>
                    <div className="col-md-3">
                      <strong>Total Amount:</strong> ₹
                      {payoutSummary?.totalAmount?.toFixed(2)}
                    </div>
                    <div className="col-md-3">
                      <strong>Total Discount:</strong> ₹
                      {payoutSummary?.totalDiscount?.toFixed(2)}
                    </div>
                    <div className="col-md-3">
                      <strong>Total Commission:</strong> ₹
                      {payoutSummary?.totalCommission?.toFixed(2)}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-3">
                      <strong>Total Confirmed Orders:</strong>{" "}
                      {payoutSummary?.totalConfirmedOrders}
                    </div>
                    <div className="col-md-3">
                      <strong>Total Confirmed Amount:</strong> ₹
                      {payoutSummary?.totalConfirmedAmount?.toFixed(2)}
                    </div>
                    <div className="col-md-3">
                      <strong>Total Confirmed Commission:</strong> ₹
                      {payoutSummary?.totalConfirmedCommission?.toFixed(2)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <strong>Net Amount:</strong> ₹
                      {payoutSummary?.netAmount?.toFixed(2)}
                    </div>
                    <div className="col-md-3">
                      <strong>Paid Amount:</strong> ₹
                      {payoutSummary?.paidAmount?.toFixed(2)}
                    </div>
                    <div className="col-md-3">
                      <strong>Remaining Amount:</strong> ₹
                      {payoutSummary?.remainingAmount?.toFixed(2)}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            )}
          </Card.Header>
          <Card.Body className="p-0 h-100 d-flex flex-column border-top">
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Order Id</th>
                  <th className="text-nowrap">Total Payment</th>
                  <th>Discount</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th className="text-nowrap">Order Status</th>
                  {/* <th>Items</th> */}
                </tr>
              </thead>
              <tbody>
                {Object.keys(payoutSummary).length > 0 &&
                Object.keys(payoutSummary.itemsList).length > 0 ? (
                  payoutSummary.itemsList.map((payout, key) => {
                    return (
                      <tr key={key}>
                        <td width={"5%"}>{key + 1}</td>
                        <td>
                          <span
                            style={{
                              backgroundColor: "yellow",
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            {payout?.orderId}
                          </span>
                        </td>

                        <td>{payout?.totalAmount}</td>
                        <td>{payout?.discount}</td>
                        <td>{payout?.quantity}</td>
                        <td>{payout?.price}</td>
                        <td>
                          {payout?.paymentStatus == "Pending"
                            ? "Payment Pending"
                            : payout?.orderStatus}
                        </td>
                        {/* <td></td> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={8}>
                      <span className="d-flex justify-content-center">
                        No Data Found &#x1F609;
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Card.Footer className="border p-0 mt-auto">
              <TablePageination
                data={payoutSummary}
                url="/payout/summary"
                search={allQueryParams}
              />
            </Card.Footer>
          </Card.Body>
        </Card>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default PayoutSummary;
