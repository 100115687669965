import React, { useEffect, useState } from "react";
import "./IndividualRegistration.scss";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { MdBackspace } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { registerFormKeys } from "helpers/formKeys";
import axios from "axios";
import { getToast } from "modules/common/toast";
import { apiCall } from "helpers/apiCalls";
import SelectPlayerPosition from "components/common/Form/SelectPlayerPosition";
import placeholder1 from "../../../../assets/images/placeholder/placeholder1.jpg";
import SelectGender from "../../../../components/common/Form/SelectGender";

import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css';
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import { post } from "helpers/apiConfig";
import { uploadImageToAzure } from "utils/blobUtils";
import { notification } from "antd";
import SelectCountry from "components/common/Form/SelectCountry";

const IndividualRegistration = () => {
  const [formData, setFormData] = useState();
  const [formError, setFormError] = useState({});
  const [roles, setRoles] = useState({});
  const [showPopup, setShowPopup] = useState(false);


  const [defaultCountryCode, setDefaultCountryCode] = useState('');
  let navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileChange = (file) => {
    handleFieldChange({ target: { name: "profile_image", value: file } });
  };

  const handleFieldChange = (e, action) => {
    let value = "";
    let newFormData;
    if (action) {
      newFormData = {
        ...formData,
        [action.name]: e ? e.value : null,
        [action.name + "_ref"]: e,
      };
    } else {
      if (e.target.name === "role_id") {
        if (e.target.checked) {
          roles.filter((item) => {
            if (item.slug === "player") value = item.id;
          });
        } else {
          roles.filter((item) => {
            if (item.slug === "agent") value = item.id;
          });
        }
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    }
    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError({});
    setLoading(true);

    let newFormData = setNewFormData(removeRefData(formData));

    try {
      if (image) {
        const url = await uploadImageToAzure(image, "images/profile-images");
        const blobName = url.split(`/images/`)[1];
        newFormData.append("avatar", blobName);
      }
      const response = await post("/vendor/register", newFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.data?.success) {
        notification.success({
          message: "Registration Successful",
          description:
            "You have successfully registered as a vendor. Admin approval will be sent to your email.",
        });
        navigate("/authenticate/login");
      } else {
        notification.error({
          message: "Registration Failed",
          description: response?.data?.message || "An error occurred.",
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      getToast(e?.response?.data?.error, "error");
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    // getData();
  }, []);

  return (
    <>
      <Card className="reg-custom-wrapper  min-vh-100 shadow">
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div className="custom-col-reg">
            <Card body className="rounded-0">
              <div className="header-form">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h2 className="login-title">Create Account</h2>
                  <img
                    src={imagePreview ? imagePreview : placeholder1}
                    alt="Icon"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "100%",
                      // borderWidth: "1px",
                      // backgroundColor: "red",
                      marginBottom: "10px",
                      objectFit: "cover", // Ensures the image fills the circular area properly
                    }}
                  />
                </div>
              </div>

              <div className="body-form d-flex justify-content-center">
                <Form className="w-75">
                  <Form.Group className="mb-2 d-flex justify-content-center">
                    <Form.Label>
                      <h6>Personal Information</h6>
                    </Form.Label>
                  </Form.Group>
                  <Row className="row-cols-1 row-cols-md-2">
                    <Col>
                      <Form.Group className="mb-2">
                        <Form.Control
                          className="custom-input"
                          name="first_name"
                          placeholder="First Name"
                          type="text"
                          value={formData?.first_name}
                          onChange={handleFieldChange}
                          isInvalid={!!formError?.first_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.first_name}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-2">
                        <Form.Control
                          className="custom-input"
                          placeholder="Email"
                          type="email"
                          name="email"
                          onChange={handleFieldChange}
                          value={formData?.email}
                        />
                      </Form.Group>

                      <Form.Group className="mb-2">
                      <SelectCountry
                        handleFieldChange={(e) => {
                          console.log("Country", e);

                          setDefaultCountryCode(e?.code?.toLowerCase());
                          setFormData({
                            ...formData,
                            region: e?.value,
                            region_ref: e,
                          });
                        }}
                        name="region"
                        placeholder="Select Region"
                        value={formData?.region_ref}
                        error={formError?.region}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.region}
                      </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-2">
                        <Form.Control
                          className="custom-input"
                          placeholder="Last Name"
                          type="text"
                          name="last_name"
                          value={formData?.last_name}
                          onChange={handleFieldChange}
                          isInvalid={!!formError?.last_name}
                        />
                      </Form.Group>

                      
                      {/* <Form.Group controlId="formFile" className="mb-2">
                        
                        <Form.Control
                          type="file"
                          size="md"
                          className="pb-2 pt-2"
                          name="profile_image"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                              setImage(file);
                              setImagePreview(URL.createObjectURL(file));
                            }
                          }}
                          isInvalid={!!formError?.profile_image}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.profile_image}
                        </Form.Control.Feedback>
                      </Form.Group> */}
                     

                      <Form.Group className="mb-2">
                        <Form.Control
                          className="custom-input"
                          placeholder="Date of Birth"
                          name="dob"
                          type="text"
                          value={formData?.dob}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")}
                          onChange={handleFieldChange}
                          isInvalid={!!formError?.dob}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.dob}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <PhoneInput
                         key={defaultCountryCode}
                          country={defaultCountryCode}
                          value={formData?.phone_number}
                          onChange={(phone, country) => {
                            console.log("Phone Number", phone, country);

                            setFormData({
                              ...formData,
                              phone_number: phone,
                              countryCode: country?.dialCode,
                            });
                          }}
                          inputClass="custom-input"
                          containerClass="w-100"
                          inputProps={{
                            name: "phone_number",
                            required: true,
                            className: "form-control",
                          }}
                          isValid={!formError?.phone_number}
                        />
                        {formError?.phone_number && (
                          <div className="invalid-feedback d-block">
                            {formError?.phone_number}
                          </div>
                        )}
                      </Form.Group>
                      
                    </Col>
                  </Row>
                  {/* <Form.Group className="mb-2 mt-2 d-flex justify-content-center">
                    <Form.Label>
                      <h6>Other Information</h6>
                    </Form.Label>
                  </Form.Group> */}
                  {/* <Row className="row-cols-1 row-cols-md-2">
                    <Form.Group className="mb-2">
                      <Form.Control
                        className="custom-input"
                        placeholder="Company Name"
                        type="text"
                        name="companyName"
                        value={formData?.companyName}
                        onChange={handleFieldChange}
                        isInvalid={!!formError?.companyName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.companyName}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <Form.Control
                        className="custom-input"
                        placeholder="Business Name"
                        type="text"
                        name="businessName"
                        value={formData?.businessName}
                        onChange={handleFieldChange}
                        isInvalid={!!formError?.businessName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.businessName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row> */}
                  {/* <Row className="row-cols-1 row-cols-md-2">
                    <Form.Group className="mb-2">
                      <Form.Control
                        className="custom-input"
                        placeholder="Registration Number"
                        type="text"
                        name="registrationNumber"
                        value={formData?.registrationNumber}
                        onChange={handleFieldChange}
                        isInvalid={!!formError?.registrationNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.registrationNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Control
                        className="custom-input"
                        placeholder="Registration Date"
                        name="registrationDate"
                        type="text"
                        value={formData?.registrationDate}
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        onChange={handleFieldChange}
                        isInvalid={!!formError?.registrationDate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.registrationDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row> */}

                  <Row className="row-cols-1 row-cols-md-2">
                  <Form.Group controlId="formFile" className="mb-2">
                        <div className="position-relative">
                          <Form.Control
                            type="file"
                            size="md"
                            className="pb-2 pt-2 opacity-0 position-absolute top-0 start-0 w-100 h-100"
                            name="profile_image"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              if (file) {
                                setImage(file);
                                setImagePreview(URL.createObjectURL(file));
                              }
                            }}
                            isInvalid={!!formError?.profile_image}
                          />
                          <div
                            className="custom-file-placeholder p-2 text-secondary border rounded text-truncate"
                            style={{
                              // maxWidth: "250px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {image ? image.name : "Choose Profile Picture"}
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {formError?.profile_image}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                      
                    

                    {/* International Vendor Checkbox */}
                    <Form.Group
                      className="mb-2 align-items-center mt-2"
                      style={{
                        transition: "0.3s ease-in-out",
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        label={
                          <span
                            className="fw-bold text-primary"
                            style={{ fontSize: "0.6rem" }} // Adjusts font size (approx. 14px)
                          >
                            International Vendor <br className="d-md-none" />
                            <div
                              className="text-muted"
                              style={{ fontSize: "0.5rem" }}
                            >
                              (For Vendors Outside the UK)
                            </div>
                          </span>
                        }
                        checked={formData?.isInternationalVendor || false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setShowPopup(true);
                          } else {
                            setFormData({
                              ...formData,
                              isInternationalVendor: false,
                            });
                          }
                        }}
                      />
                    </Form.Group>

                    {(defaultCountryCode == "gb" && !formData?.isInternationalVendor) && <Form.Group className="mb-2">
                        <Form.Control
                          className="custom-input"
                          name="shareCode"
                          placeholder="Share Code"
                          type="text"
                          value={formData?.shareCode}
                          onChange={handleFieldChange}
                          isInvalid={!!formError?.shareCode}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.shareCode}
                        </Form.Control.Feedback>
                      </Form.Group>}

                    <Modal
                      show={showPopup}
                      onHide={() => setShowPopup(false)}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>International Vendor Feature</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          This feature is only for vendors outside the UK. If
                          you enable this, you will not be able to manage order
                          statuses. Only the admin will have control over order
                          status updates. However, you can still access and use
                          other vendor features.
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowPopup(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => {
                            setFormData({
                              ...formData,
                              isInternationalVendor: true,
                            });
                            setShowPopup(false);
                          }}
                        >
                          Confirm
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Row>

                  <Form.Group className="mb-2 mt-2 d-flex justify-content-center">
                    <Form.Label>
                      <h6>Account Information</h6>
                    </Form.Label>
                  </Form.Group>
                  <Row className="row-cols-1 row-cols-md-2">
                    <Col>
                      <Form.Group className="mb-2">
                        <Form.Control
                          className="custom-input"
                          placeholder="Password"
                          // type="password"
                          name="password"
                          value={formData?.password}
                          onChange={handleFieldChange}
                          isInvalid={!!formError?.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-2">
                        <Form.Control
                          className="custom-input"
                          placeholder="Confirm Password"
                          type="password"
                          name="c_password"
                          value={formData?.c_password}
                          onChange={handleFieldChange}
                          isInvalid={!!formError?.c_password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.c_password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex flex-column flex-md-row justify-content-between mt-3 mb-3 align-items-center">
                    <Link
                      to="/authenticate/login"
                      className="text-muted mb-2 mb-md-0"
                    >
                      Already have an account? Login here
                    </Link>

                    <Button
                      size="md"
                      style={{
                        backgroundColor: "#9D2233",
                        borderColor: "#9D2233",
                        color: "#fff",
                        width: "150px",
                      }}
                      onClick={handleSubmit}
                      disabled={loading} // Disable button when loading
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                          Registering...
                        </>
                      ) : (
                        "Register"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default IndividualRegistration;
