import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import { get } from "helpers/apiConfig";

const SelectSize = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    // If `value` is a string, fetch the options to match it to an object
    const fetchSelectedOption = async () => {
      if (typeof value === "string") {
        const options = await setItems(value);
        const matchedOption = options.find((option) => option.value === value);
        setSelectedValue(matchedOption || null);
      } else {
        setSelectedValue(value); // If already an object, set directly
      }
    };
    fetchSelectedOption();
  }, [value]);

  const setItems = async (inputValue) => {
    const response = await get("/admin/sizes", {
      // params: { name: inputValue },
    });
    const data = response && response.data ? response.data.data : [];
    return data
      .filter((item) =>
        item.size.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        label: item.size,
        value: keyName ? item[keyName] : item.size,
      }));
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={setItems}
      defaultOptions
      isClearable
      name={name ?? "size"}
      value={selectedValue}
      placeholder={placeholder ?? "Select Size"}
      onChange={(selectedOption) => {
        setSelectedValue(selectedOption);
        handleFieldChange({ target: { name, value: selectedOption?.value } });
      }}
      className={error && "form-control ps-0 py-0 is-invalid"}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          height: 42,
          minHeight: 42,
          ...style,
        }),
      }}
    />
  );
};

SelectSize.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.string, // For the initial string value
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ]),
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectSize;
