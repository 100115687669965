import { AuthWizardContext } from "context/Context";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

const LandingPage = () => {
  const { user } = useContext(AuthWizardContext);
  return user ? (
    <Navigate to="/dashboard" replace={true} />
  ) : (
    <Navigate to="/authenticate/login" replace={true} />
  );
};

export default LandingPage;
