import React from "react";
import { Outlet } from "react-router-dom";
import "../assets/style/AuthScreen.scss";

const AuthOutlet = () => {
  return (
    <div className="auth-screen-wrapper d-flex flex-column justify-content-center align-items-center p-0 m-0">
      <Outlet />
    </div>
  );
};

export default AuthOutlet;
