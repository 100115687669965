const createFilterArray = (param, options = null) => {
  let filterItemsArray = param.split(",,");
  return filterItemsArray.reduce((arrayItems, item) => {
    let newItem = JSON.parse(item);
    if (options && options.includes(newItem.column)) {
      arrayItems.push(newItem);
    } else if (!options) {
      arrayItems.push(newItem);
    }
    return arrayItems;
  }, []);
};

export default createFilterArray;
