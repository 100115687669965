import React from "react";
import { toast } from "react-toastify";

import { MdDoneAll, MdOutlineErrorOutline } from "react-icons/md";

export const getToast = (message, type) => {
  toast(message, {
    theme: "light",
    position: "top-center",
    type: "default",
    autoClose: 2000,
    className: type === "success" ? "success" : "warnings",
    icon:
      type === "success" ? (
        <MdDoneAll size={20} className="text-success" />
      ) : (
        <MdOutlineErrorOutline size={20} className="text-danger" />
      ),
  });
};
