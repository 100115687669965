import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Redirect, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/ddot-dashboard-react.scss?v=2.0.0";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import App from "App";
import AuthenticationProviders from "providers/AuthenticationProviders";
// import AdminLayout from "layouts/Admin.js";
import "helpers/initFA";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthenticationProviders>
        <App />
      </AuthenticationProviders>
    </BrowserRouter>
  </React.StrictMode>
);

// root.render(
//   <BrowserRouter>
//     <Routes>
//       <Route path="/" to={(props) => <AdminLayout {...props} />} />
//       {/* <Redirect from="/" to="/admin/dashboard" /> */}
//     </Routes>
//   </BrowserRouter>
// );
