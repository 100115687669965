import React, { Component, Fragment } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Image, Nav } from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";
import Logo from "../../assets/images/logo_rb.png";
import "./sidebar.css";
import { Colors } from "constants/colors";

const Sidebar = ({ color, image, routes }) => {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1
      ? "active"
      : routeName.includes("settings")
      ? "collapsed"
      : "";
  };
  const activeSubRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "show" : "";
  };
  return (
    <div
      className="sidebar sidebar-wrapper"
      data-image={image}
      data-color={color}
    >
      <div
        className="sidebar-background"
        style={{ backgroundColor: Colors.black }}
      />
      <div className="sidebar-wrapper custom-sidebar-wrapper">
        <div className="logo">
          <div className="d-flex align-items-center">
            <div className="logo-img">
              <Image
                src={Logo}
                alt="..."
                className="ms-3 tinted-image"
                height={100}
                width={100}
              />
            </div>
            <span
              className="simple-text"
              style={{
                fontSize: "16px",
                lineHeight: "1",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                className="first-line"
                style={{ color: "#9D2233", fontWeight: "bold", marginRight: 5 }}
              >
                D.
              </span>
            </span>
          </div>
        </div>

        <Nav className="nav flex-column">
          {routes.map((prop, key) => (
            <Fragment key={key}>
              {prop.child ? (
                <li className={activeRoute(prop.path)} key={key} to>
                  <NavLink
                    className={"nav-link"}
                    to={prop.path}
                    data-bs-toggle="collapse"
                    data-bs-target={`#${prop.path}`}
                  >
                    <div className="d-lg-flex d-md-flex d-none menuFlex">
                      <span className=" side-nav-icon">{prop.icon}</span>
                      <p className="side-nav-text">{prop.name}</p>
                      <span className=" drop-down-icon">
                        <FaAngleDown />
                      </span>
                    </div>

                    <div className="d-lg-none d-md-none d-flex menuFlex">
                      <div className=" side-nav-icon d-flex align-items-center">
                        {prop.icon} <FaAngleDown />
                      </div>
                      <div className="mob-text">{prop.name}</div>
                    </div>
                  </NavLink>
                  <ul className="list-unstyled">
                    <li
                      className={activeSubRoute(prop.path) + " collapse"}
                      id={`${prop.path}`}
                    >
                      {prop.children.map((subMenu, subKey) => (
                        <NavLink
                          className="nav-link"
                          to={subMenu.path}
                          key={subKey}
                        >
                          <div className="d-flex main-menu menuFlex">
                            <div>
                              <span className=" side-nav-icon">
                                {subMenu.icon}
                              </span>
                              <p className="side-nav-text">{subMenu.name}</p>
                            </div>

                            <div className="mob-text">{subMenu.name}</div>
                          </div>
                        </NavLink>
                      ))}
                    </li>
                  </ul>
                </li>
              ) : (
                <li className={activeRoute(prop.path)} key={key}>
                  <NavLink className={`nav-link`} to={prop.path}>
                    <div className="d-flex main-menu menuFlex">
                      <div>
                        <span className=" side-nav-icon">{prop.icon}</span>
                        <p className="side-nav-text">{prop.name}</p>
                      </div>

                      <div className="mob-text">{prop.name}</div>
                    </div>
                  </NavLink>
                </li>
              )}
            </Fragment>
          ))}
        </Nav>
        <div className="version-container">
          <p className="version-text">
            V 1.0.06{" "}
            {process.env.REACT_APP_API_URL?.includes("local")
              ? "(Local)"
              : process.env.REACT_APP_API_URL?.includes("dev")
              ? " (Dev)"
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
