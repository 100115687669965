export const Colors = {
  primary: "#9D2233",
  primaryShade: "#FDF1F3",
  secondary: "#ffffff",
  white: "#ffffff",
  offwhite: "#ffffff10",
  black: "#000000",
  // gray: "#D3D3D3",
  gray: "gray",
  lightGray: "#F4F4F4",
  darkGray: "#272727",
  placeHolder: "#27272750",
  error: "#FF0000",
  success: "#008000",
  warning: "#FFFF00",
};
