import { React } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import { get } from "helpers/apiConfig";

const SelectSubCategory = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
}) => {

  const setItems = async (inputValue) => {
    try {
      let response = await get("/subcategories", {
        params: { name: inputValue },
      });
      let data = response?.data?.subcategories || [];

      return data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return [];
    }
  };

  const handleSelectChange = (selectedOptions) => {
    // When clearing, set value to an empty array
    const newValue = selectedOptions || [];

    // Pass the selected options or an empty array to handleFieldChange
    handleFieldChange({
      target: {
        name,
        value: newValue,
      },
    });
  };

  // Ensure value is always an array and correctly formatted
  const formattedValue = Array.isArray(value)
    ? value.map((item) => ({
        label: item.name || item.label,  // Ensure 'label' is correctly mapped
        value: item._id || item.value,   // Ensure '_id' is correctly mapped
      }))
    : [];

  return (
    <>
      <AsyncSelect
        isMulti
        cacheOptions
        loadOptions={setItems}
        defaultOptions
        isClearable
        name={name || "sub_categories"}
        value={formattedValue || []}  // Ensure value is always an array, even if empty
        placeholder={placeholder ?? "Select SubCategories"}
        onChange={handleSelectChange}
        className={error && "form-control ps-0 py-0 is-invalid"}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          control: (provided) => ({
            ...provided,
            // height: 42,
            minHeight: 42,

            ...style,
          }),
        }}
      />
    </>
  );
};

SelectSubCategory.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.array,  // Ensure value is an array for isMulti
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectSubCategory;
