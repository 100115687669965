import React from "react";
import { Outlet } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import { adminRoute , vendorRoute} from "routes.js";
import { Container } from "react-bootstrap";
import DeleteWarning from "components/common/Warnings/DeleteWarning";
import { getAdminRole } from "utils/adminUtils";
import { getAdminDetails } from "utils/adminUtils";


const Admin = () => {
  const mainPanel = React.useRef(null);

  const admin = getAdminDetails();
  const role = getAdminRole();

  return (
    <>
      <Container className="wrapper p-0" fluid>
        <Sidebar
          color={"black"}
          image={""}
          routes={role == "admin" ? adminRoute : vendorRoute}
        />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="outlet-wraper">
            <Outlet />
          </div>
        </div>
        <DeleteWarning />
      </Container>
    </>
  );
};

export default Admin;
