const removeRefData = (data, type = "object", options = {}) => {
  options = { ...{ removeValue: [], removeValueExceptKeys: [] }, ...options };
  const filterObject = (data) =>
    Object.keys(data)
      .filter((key) => !key.endsWith("_ref"))
      .reduce((initObject, key) => {
        if (
          !options.removeValue.includes(data[key]) ||
          options.removeValueExceptKeys.includes(key)
        ) {
          initObject[key] = data[key];
        }

        return initObject;
      }, {});

  if (type === "array") {
    return data?.reduce((initArray, item) => {
      initArray.push(filterObject(item));
      return initArray;
    }, []);
  } else {
    return filterObject(data);
  }
};

export default removeRefData;
