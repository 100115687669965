import axios from "axios";
import SelectCity from "components/common/Form/SelectCity";
import SelectCountry from "components/common/Form/SelectCountry";
import SelectNationality from "components/common/Form/SelectNationality";
import SelectPlayerPosition from "components/common/Form/SelectPlayerPosition";
import ListLoading from "components/common/LoadingScreen/ListLoading";
import { apiCall } from "helpers/apiCalls";
import { registerFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import { getToast } from "modules/common/toast";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Offcanvas,
  Row,
  Spinner,
  Toast,
} from "react-bootstrap";
import { FaUserEdit, FaUserPlus } from "react-icons/fa";
import { useNavigate, useOutletContext, useParams } from "react-router";
import PhoneInput from "react-phone-input-2";
import { getAdminDetails } from "utils/adminUtils";
import { removeImageFromAzure } from "utils/blobUtils";
import { uploadImageToAzure } from "utils/blobUtils";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";

const UpdateProfileForm = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState();
  const [roles, setRoles] = useState({});
  const [formError, setFormError] = useState({});
  //   const [getData] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const user = getAdminDetails();
  const [save, setSave] = useState(false);

  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");

  const handleFieldChange = (e, action) => {
    let newFormData = "";
    let value;
    if (action) {
      newFormData = {
        [action.name]: e ? e.value : null,
        [action.name + "_ref"]: e,
      };
    } else {
      if (e.target.name === "profile_picture") {
        value = e.target.files[0];
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    }
    setFormData({ ...formData, ...newFormData });
  };

  const getDataForm = async () => {
    setLoading(true);

    if (user?._id) {
      let data = await apiCall({
        url: "/vendor/" + user?._id,
      });

      if (data?.success) {
        const regionData = {
          label: data?.data?.regionDetails?.name,
          value: data?.data?.regionDetails?._id,
        };
        setFormData({
          first_name: data?.data?.first_name,
          last_name: data?.data?.last_name,
          phone_number: data?.data?.phone_number,
          email: data?.data?.email,
          // avatar: data?.data?.avatar,
          companyName: data?.data?.companyName,
          businessName: data?.data?.businessName,
          registrationNumber: data?.data?.registrationNumber,
          registrationDate: data?.data?.registrationDate,
          countryCode: data?.data?.countryCode,
          commissionPercentage: data?.data?.commissionPercentage,
          minimumOrderAmountForCommission:
            data?.data?.minimumOrderAmountForCommission,
          verified_document_type: data?.data?.verified_document_type,
          verified_document_details:
            data?.data?.verified_document_details ?? "",
          region: data?.data?.region,
          region_ref: data?.data?.region ? regionData : null,
          isInternationalVendor: data?.data?.isInternationalVendor ?? false,
        });

        setPreview(data?.data?.avatar);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataForm();
  }, []);

  const handleSubmit = async (e) => {
    setSave(true);
    e.preventDefault();
    setFormError({});

    let newFormData = setNewFormData(removeRefData(formData));
    if (image) {
      let dp = await uploadImageToAzure(image, "images/sizechart-images");
      const blobName = dp.split(`/images/`)[1];
      newFormData.append("avatar", blobName);
      await removeImageFromAzure(
        IMAGE_URL + user?.avatar,
        "images/product-images"
      );
    }

    axios({
      method: "PUT",
      url: "/admin/vendor/" + user?._id,
      data: newFormData,
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": id ? "application/json" : "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          localStorage.setItem(
            "LOGGED_USER",
            JSON.stringify(res?.data?.seller)
          );

          setFormData({});

          getToast(res.data.message, "success");

          // navigate(-1);
          window.location.href = "/dashboard";
        }
        setSave(false);
      })
      .catch((error) => {
        setSave(false);

        if (error?.response?.data && error?.response?.data.message) {
          getToast(error.response.data.message, "error");

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          getToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
      });
  };

  return (
    <>
      <Offcanvas
        show
        onHide={() => navigate(-1)}
        placement="end"
        style={{ minWidth: "100%" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <FaUserEdit size={25} className="me-2" />
            Update Profile
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!loading ? (
            <Form>
              <Row>
                <Col md={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">First Name</Form.Label>
                    <Form.Control
                      name="first_name"
                      placeholder="First Name"
                      type="text"
                      value={formData?.first_name}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.first_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">Email</Form.Label>
                    <Form.Control
                      placeholder="Email"
                      type="email"
                      name="email"
                      onChange={handleFieldChange}
                      value={formData?.email}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Company Name
                    </Form.Label>
                    <Form.Control
                      placeholder="Company Name"
                      type="text"
                      name="companyName"
                      value={formData?.companyName}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.companyName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.companyName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Registration Number
                    </Form.Label>
                    <Form.Control
                      placeholder="Registration Number"
                      type="text"
                      name="registrationNumber"
                      value={formData?.registrationNumber}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.registrationNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.registrationNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <SelectCountry
                      handleFieldChange={(e) => {
                        console.log("Country", e?.value);
                        setFormData({
                          ...formData,
                          region: e?.value,
                          region_ref: e,
                        });
                      }}
                      name="region"
                      placeholder="Select Region"
                      value={formData?.region_ref}
                      error={formError?.region}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.region}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      onChange={handleFieldChange}
                      value={formData?.last_name}
                      placeholder="Enter Last Name"
                      isInvalid={!!formError?.last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.last_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <PhoneInput
                      country={"gb"}
                      value={formData?.phone_number}
                      onChange={(phone, country) => {
                        console.log("Phone Number", phone, country);

                        setFormData({
                          ...formData,
                          phone_number: phone,
                          countryCode: country?.dialCode,
                        });
                      }}
                      inputClass="custom-input"
                      containerClass="w-100"
                      inputProps={{
                        name: "phone_number",
                        required: true,
                        className: "form-control",
                      }}
                      isValid={!formError?.phone_number}
                    />
                    {formError?.phone_number && (
                      <div className="invalid-feedback d-block">
                        {formError?.phone_number}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Business Name
                    </Form.Label>
                    <Form.Control
                      placeholder="Business Name"
                      type="text"
                      name="businessName"
                      value={formData?.businessName}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.businessName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.businessName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Registration Date
                    </Form.Label>
                    <Form.Control
                      placeholder="Registration Date"
                      name="registrationDate"
                      type="text"
                      value={formData?.registrationDate}
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.registrationDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.registrationDate}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-2 align-items-center mt-2"
                    style={{
                      transition: "0.3s ease-in-out",
                    }}
                  >
                    <Form.Check
                    disabled
                      type="checkbox"
                      label={
                        <span
                          className="fw-bold text-primary"
                          style={{ fontSize: "0.7rem" }} // Adjusts font size (approx. 14px)
                        >
                          International Vendor <br className="d-md-none" />
                          <div
                            className="text-muted"
                            style={{ fontSize: "0.7rem" }}
                          >
                            (For Vendors Outside the UK)
                          </div>
                        </span>
                      }
                      checked={formData?.isInternationalVendor || false}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setFormData((prev) => ({
                          ...prev,
                          isInternationalVendor: isChecked,
                        }));
                        if (isChecked) {
                          setShowPopup(true);
                        }
                      }}
                    />
                  </Form.Group>

                  <Modal
                    show={showPopup}
                    onHide={() => setShowPopup(false)}
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>International Vendor Feature</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        This feature is only for vendors outside the UK. If you
                        enable this, you will not be able to manage order
                        statuses. Only the admin will have control over order
                        status updates. However, you can still access and use
                        other vendor features.
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setShowPopup(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            isInternationalVendor: true,
                          });
                          setShowPopup(false);
                        }}
                      >
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Minimum Order Amount for Commission
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter minimum order amount"
                      name="minimumOrderAmountForCommission"
                      value={formData?.minimumOrderAmountForCommission}
                      // onChange={handleFieldChange}
                      onClick={() =>{
                        // toast
                        // getToast("⚠️ This can only be changed and decided by the admin.", "error")
                      }}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Commission Percentage
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter commission percentage"
                      name="commissionPercentage"
                      disabled
                      value={formData?.commissionPercentage}
                      // onChange={handleFieldChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Verified Document Type
                    </Form.Label>
                    <Form.Select
                      name="verified_document_type"
                      value={formData?.verified_document_type}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.verified_document_type}
                    >
                      <option value="">Select Document Type</option>
                      <option value="PASSPORT">Passport</option>
                      <option value="LICENSE">License</option>
                      <option value="NATIONAL_ID">National ID</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formError?.verified_document_type}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Verified Document Details
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter verified document details"
                      name="verified_document_details"
                      value={formData?.verified_document_details}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.verified_document_details}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.verified_document_details}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="imageUpload">
                    <Form.Label className="require-data">
                      Profile Picture
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="image"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setImage(file);
                          setPreview(URL.createObjectURL(file));
                        }
                      }}
                      isInvalid={!!formError.sizeChart}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.sizeChart}
                    </Form.Control.Feedback>
                    <div className="mt-3">
                      {preview && (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={
                              preview?.includes("http")
                                ? preview
                                : IMAGE_URL + preview
                            }
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setPreview(null);
                              setImage(null);
                            }}
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-5px",
                              background: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            ×
                          </button>
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex mt-3">
                <Button
                  size="md"
                  className="ms-auto"
                  variant={id ? "primary" : "success"}
                  onClick={handleSubmit}
                >
                  {save ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="m-0 ms-2"
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </div>
            </Form>
          ) : (
            <ListLoading />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default UpdateProfileForm;
