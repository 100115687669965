export const getAdminDetails = () => {
  return JSON.parse(localStorage.getItem("LOGGED_USER") || "{}");
};

import { apiCall } from "helpers/apiCalls";

export const getAdminDetailsWithApi = async () => {
  let admin = JSON.parse(localStorage.getItem("LOGGED_USER") || "{}");

  if (admin?._id) {
    try {
      const response = await apiCall({
        url: `/vendor/${admin._id}`,
      });

      console.log("response", response?.data);

      if (response?.data) {
        localStorage.setItem("LOGGED_USER", JSON.stringify(response.data)); // Update storage
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching admin details:", error);
    }
  }

  return admin;
};

export const getAdminRole = () => {
  const admin = getAdminDetails();
  return admin?.role || null; // Returns role or null if not found
};
