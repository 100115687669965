import axios from "axios";
import SelectBrand from "components/common/Form/SelectBrand";
import SelectCategory from "components/common/Form/SelectCategory";
import SelectCity from "components/common/Form/SelectCity";
import SelectColor from "components/common/Form/SelectColor";
import SelectCountry from "components/common/Form/SelectCountry";
import SelectGender from "components/common/Form/SelectGender";
import SelectNationality from "components/common/Form/SelectNationality";
import SelectPlayerPosition from "components/common/Form/SelectPlayerPosition";
import SelectSize from "components/common/Form/SelectSize";
import SelectSubCategory from "components/common/Form/SelectSubCategory";
import ListLoading from "components/common/LoadingScreen/ListLoading";
import { apiCall } from "helpers/apiCalls";
import { put } from "helpers/apiConfig";
import { post } from "helpers/apiConfig";
import { get } from "helpers/apiConfig";

import { getToast } from "modules/common/toast";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";
import { FaBoxOpen, FaListAlt, FaUserPlus } from "react-icons/fa";
import { MdDelete, MdRemove } from "react-icons/md";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { removeImageFromAzure } from "utils/blobUtils";
import { uploadImageToAzure } from "utils/blobUtils";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";

const ProductForm = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState();
  const [roles, setRoles] = useState({});
  const [formError, setFormError] = useState({});
  const [getData, pageNumber] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [save, setSave] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);

  const [sizes, setSizes] = useState([]);
  const [sizeFields, setSizeFields] = useState([]);

  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [sizeChartImage, setSizeChartImage] = useState(null);
  const [sizeChartPreview, setSizeChartPreview] = useState("");

  const validateForm = () => {
    const errors = {};

    if (!formData?.title?.trim()) {
      errors.title = "Title is required";
    }

    if (!formData?.brand) {
      errors.brand = "Brand is required";
    }

    if (!formData?.material?.trim()) {
      errors.material = "Material is required";
    }

    if (!formData?.color) {
      errors.color = "Color is required";
    }

    if (!formData?.gender) {
      errors.gender = "Gender is required";
    }

    if (!formData?.main_category) {
      errors.main_category = "Main Category is required";
    }

    if (!formData?.sub_categories?.length) {
      errors.sub_categories = "Sub Categories are required";
    }

    if (!formData?.description?.trim()) {
      errors.description = "Description is required";
    }

    if (!previewUrls?.length) {
      errors.images = "Product Images are required";
    }

    if (!sizeFields?.length) {
      errors.sizes = "";
      getToast("At least one size is required", "error");
    }

    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFieldChange = (e, action) => {
    let newFormData = {};
    let value;

    if (action && action.name) {
      newFormData = {
        [action.name]: e ? e.value : null,
        // [action.name + "_ref"]: e?.label,
      };
    } else if (e && e.target) {
      const { name, files, value: inputValue } = e.target;
      value = inputValue;

      newFormData = {
        ...formData,
        [name]: value,
      };
    } else {
      console.error("Unexpected input format:", e, action);
    }

    setFormData({ ...formData, ...newFormData });
  };

  const handleSubcategories = (e, action) => {
    const { name, value } = e.target;

    setFormData({ ...formData, sub_categories: value });
  };

  const handleAddSize = () => {
    setSizeFields([
      ...sizeFields,
      { size: "", price: "0", discount: "0", quantity: "0" },
    ]);
  };

  const handleRemoveSize = (index) => {
    const updatedSizes = sizeFields.filter((_, i) => i !== index);
    setSizeFields(updatedSizes);
  };
  const handleSizeFieldChange = (index, field, value) => {
    // console.log(index, field, value);

    const updatedSizes = sizeFields.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );

    // console.log(updatedSizes);

    setSizeFields(updatedSizes);
  };

  const getDataForm = async () => {
    setLoading(true);
    if (id) {
      let data = await get("/panel/product/" + id);

      const product = data?.data?.product;
      setEditingProduct(product);
      const updatedSubCategories =
        product?.subcategoryDetails?.map((category) => ({
          label: category.name,
          value: category._id,
        })) || [];

      setFormData({
        ...formData,
        title: product?.title,
        description: product?.description,
        material: product?.material,
        color: product?.color,
        main_category: product?.main_category,
        sub_categories: updatedSubCategories,
        gender: product?.gender,
        expectedDeliveryInDays: product?.expectedDeliveryInDays,
        brand: product?.brand,
      });

      setPreviewUrls(product?.images);
      setSizeChartPreview(product?.sizeChart);
      // setImages(product?.images);
      // setSizeImage(product?.sizeChart);

      setSizeFields(
        product.sizes?.map((size, index) => ({
          ...size,
          key: index,
        }))
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataForm();
  }, [id]);

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setSave(true);

    try {
      const data = new FormData();
      const imageUrls = [];
      if (files.length > 0) {
        for (const file of files) {
          const url = await uploadImageToAzure(file, "images/product-images");
          const blobName = url.split(`/images/`)[1];
          // console.log("blobName, ", blobName);
          imageUrls.push(blobName);
        }
      }

      if (sizeChartImage) {
        let sizeChart = await uploadImageToAzure(
          sizeChartImage,
          "images/sizechart-images"
        );
        const blobName = sizeChart.split(`/images/`)[1];
        data.append("sizeChart", blobName);
      }

      if (editingProduct) {
        let listFile = previewUrls?.filter(
          (item, index) => !item?.includes("http")
        );
        imageUrls.push(...listFile);

        const difference = editingProduct?.images?.filter(
          (item) => !previewUrls.includes(item)
        );

        if (difference.length > 0) {
          difference.forEach(async (item) => {
            await removeImageFromAzure(
              IMAGE_URL + item,
              "images/product-images"
            );
          });
        }
      }

      imageUrls.forEach((url, index) => {
        data.append(`convertedImages[${index}]`, url);
      });

      if (sizeFields?.length > 0) {
        sizeFields?.forEach((size, index) => {
          data.append(`productSizes[${index}].size`, size.size);
          data.append(`productSizes[${index}].price`, size.price);
          data.append(`productSizes[${index}].quantity`, size.quantity);
          data.append(`productSizes[${index}].discount`, size.discount);
        });
      } else {
        throw new Error("No sizes provided");
      }

      for (const key in formData) {
        if (key !== "images") {
          if (key === "sub_categories" && Array.isArray(formData[key])) {
            const subCategoriesStr = formData[key]
              .map((sub) => sub.value)
              .join(",");
            data.append("sub_categories", subCategoriesStr);
          } else {
            data.append(key, formData[key]);
          }
        }
      }

      let res;

      if (id) {
        res = await put(`/admin/product/${id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        res = await post(`/admin/product/new`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      if (res?.data?.success) {
        getData(pageNumber);
        navigate(-1);
        // succes toast
        getToast("Product has been saved successfully", "success");
        setSave(false);
      } else {
        // error toast
        setSave(false);
        getToast("Error occurred while saving product", "error");
      }
    } catch (err) {
      setSave(false);

      

      console.log(err?.response);
      getToast(err?.response?.data?.message, "error");
    }
  };

  const getAllSizes = async () => {
    try {
      const response = await get(`/admin/sizes`);
      if (response.data.success) {
        setSizes(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getAllSizes();
  }, []);

  return (
    <>
      <Offcanvas
        show
        onHide={() => navigate(-1)}
        placement="end"
        style={{ minWidth: "70%" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <FaListAlt size={25} className="me-2" /> {id ? "Edit" : "Add"}{" "}
            Product
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!loading ? (
            <Form>
              <Row>
                <Col md={4} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      onChange={handleFieldChange}
                      value={formData?.title}
                      placeholder="Enter Title"
                      isInvalid={!!formError.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.title}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput7"
                  >
                    <Form.Label className="require-data">Brand</Form.Label>
                    <SelectBrand
                      handleFieldChange={handleFieldChange}
                      name="brand"
                      placeholder="Select Brand"
                      value={formData?.brand}
                      error={formError?.brand}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.brand}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">Material</Form.Label>
                    <Form.Control
                      type="text"
                      name="material"
                      onChange={handleFieldChange}
                      value={formData?.material}
                      placeholder="Enter Material"
                      isInvalid={!!formError.material}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.material}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput7"
                  >
                    <Form.Label className="require-data">Color</Form.Label>
                    <SelectColor
                      handleFieldChange={handleFieldChange}
                      name="color"
                      placeholder="Select Color"
                      value={formData?.color}
                      error={formError?.color}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.color}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput7"
                  >
                    <Form.Label className="require-data">Gender</Form.Label>
                    <SelectGender
                      handleFieldChange={handleFieldChange}
                      name="gender"
                      placeholder="Select Gender Tagging"
                      value={formData?.gender}
                      s
                      error={formError?.gender}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.gender}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput7"
                  >
                    <Form.Label className="require-data">
                      Main Category
                    </Form.Label>
                    <SelectCategory
                      handleFieldChange={handleFieldChange}
                      name="main_category"
                      placeholder="Select Category"
                      value={formData?.main_category}
                      error={formError?.main_category}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.main_category}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput7"
                  >
                    <Form.Label className="require-data">
                      Expected Delivery
                    </Form.Label>
                    <Form.Select
                      name="expectedDeliveryInDays"
                      value={formData?.expectedDeliveryInDays}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          expectedDeliveryInDays: e.target.value,
                        });
                      }}
                      isInvalid={!!formError?.expectedDeliveryInDays}
                    >
                      <option value="" disabled>
                        Select expected Delivery
                      </option>
                      {[0, 1, 2, 3, 4, 5, 6, 7].map((day) => (
                        <option key={day} value={day}>
                          {day === 0
                            ? "Same Day"
                            : `Within ${day} Day${day > 1 ? "s" : ""}`}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formError?.expectedDeliveryInDays}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={8} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label className="require-data">
                      Description
                    </Form.Label>
                    <Form.Control
                      className="custom-input"
                      placeholder="Enter Description"
                      type="text"
                      name="description"
                      value={formData?.description}
                      onChange={handleFieldChange}
                      isInvalid={!!formError.description}
                      as="textarea"
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput7"
                  >
                    <Form.Label className="require-data">
                      Sub Categories
                    </Form.Label>
                    <SelectSubCategory
                      handleFieldChange={handleSubcategories}
                      name="sub_categories"
                      value={formData?.sub_categories ?? []}
                      placeholder="Select Subcategories"
                      error={formError?.sub_categories}
                      style={{ border: "1px solid #ccc" }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.sub_categories}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="imageUpload">
                    <Form.Label className="require-data">
                      Product Images
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="images"
                      onChange={(event) => {
                        const selectedFiles = Array.from(event.target.files);
                        const newPreviewUrls = selectedFiles.map((file) =>
                          URL.createObjectURL(file)
                        );
                        setFiles([...files, ...selectedFiles]);
                        setPreviewUrls([...previewUrls, ...newPreviewUrls]);
                      }}
                      multiple
                      isInvalid={!!formError.images}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.images}
                    </Form.Control.Feedback>
                    {/* Display previews of selected images */}
                    <div className="mt-3">
                      {previewUrls?.length > 0 &&
                        previewUrls?.map((url, index) => (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              src={
                                url?.includes("http") ? url : IMAGE_URL + url
                              }
                              alt={`Preview ${index}`}
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                            <button
                              type="button"
                              onClick={() => {
                                // Remove the image and corresponding file
                                const newPreviewUrls = previewUrls.filter(
                                  (item, idx) => idx !== index
                                );
                                const newFiles = files.filter(
                                  (item, idx) => idx !== index
                                );

                                setPreviewUrls(newPreviewUrls);
                                setFiles(newFiles);
                              }}
                              style={{
                                position: "absolute",
                                top: "-5px",
                                right: "-5px",
                                background: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              ×
                            </button>
                          </div>
                        ))}
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Size and Pricing</Form.Label>
                    {sizeFields.map((field, index) => (
                      <div
                        key={index}
                        className="d-flex mb-2 align-items-center"
                      >
                        <SelectSize
                          handleFieldChange={(e) => {
                            handleSizeFieldChange(
                              index,
                              "size",
                              e?.target?.value
                            );
                          }}
                          name="size"
                          value={field?.size}
                          // placeholder="Select Size"
                          // error={formError?.sub_categories}
                          style={{
                            border: "1px solid #ccc",
                            minWidth: "150px",
                          }}
                        />
                        <FormControl
                          type="text"
                          style={{
                            height: "42px",
                            marginLeft: "10px",
                          }}
                          placeholder="Price"
                          // value={field.price}
                          value={field.price == 0 ? "" : field.price}
                          onChange={(e) =>
                            handleSizeFieldChange(
                              index,
                              "price",
                              e.target.value
                            )
                          }
                          className="me-2"
                        />
                        <FormControl
                          type="text"
                          style={{
                            height: "42px",
                          }}
                          placeholder="Discount (£)"
                          // value={field.discount ?? 0}
                          value={field.discount == 0 ? "" : field.discount}

                          onChange={(e) =>
                            handleSizeFieldChange(
                              index,
                              "discount",
                              e.target.value
                            )
                          }
                          className="me-2"
                        />
                        <FormControl
                          type="text"
                          style={{
                            height: "42px",
                          }}
                          placeholder="Quantity"
                          // value={field.quantity}
                          value={field.quantity == 0 ? "" : field.quantity}
                          onChange={(e) =>
                            handleSizeFieldChange(
                              index,
                              "quantity",
                              e.target.value
                            )
                          }
                          className="me-2"
                        />
                        <Button
                          style={{
                            height: "40px",
                          }}
                          onClick={() => handleRemoveSize(index)}
                          variant="outline-danger"
                        >
                          <MdDelete />
                        </Button>
                      </div>
                    ))}
                    <td></td>
                    <Button variant="outline-primary" onClick={handleAddSize}>
                      Add Size
                    </Button>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="imageUpload">
                    <Form.Label className="require-data">
                      Size Chart (Optional)
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="sizeChart"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setSizeChartImage(file);
                          setSizeChartPreview(URL.createObjectURL(file));
                        }
                      }}
                      isInvalid={!!formError.sizeChart}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.sizeChart}
                    </Form.Control.Feedback>
                    <div className="mt-3">
                      {sizeChartPreview && (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={
                              sizeChartPreview?.includes("http")
                                ? sizeChartPreview
                                : IMAGE_URL + sizeChartPreview
                            }
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setSizeChartImage(null);
                              setSizeChartPreview(null);
                            }}
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-5px",
                              background: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            ×
                          </button>
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex mt-3">
                <Button
                  size="md"
                  className="ms-auto"
                  variant={id ? "primary" : "success"}
                  onClick={handleSubmit}
                >
                  {save ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="m-0 ms-2"
                    />
                  ) : id ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Form>
          ) : (
            <ListLoading />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ProductForm;
