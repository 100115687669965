
// import { React, useEffect, useState } from "react";
// import axios from "axios";
// import AsyncSelect from "react-select/async";
// import PropTypes from "prop-types";
// import { get } from "helpers/apiConfig";

// const SelectBrand = ({
//   handleFieldChange,
//   name,
//   error,
//   value,
//   keyName,
//   style,
//   placeholder,
// }) => {
//   const [selectedItem, setSelectedItem] = useState(null); // Store the selected item object
//   const [data, setData] = useState([]);

//   const setItems = async () => {
//     let response = await get("/admin/brand", {});
//     let fetchData = response && response.data ? response.data.brands : [];
//     if (fetchData.length > 0) {
//       setData(fetchData);
//     }
//     // Map data for the select options
//     return fetchData.map((item) => ({
//       label: item.brand,
//       value: keyName ? item[keyName] : item._id,
//     }));
//   };

//   useEffect(() => {
//     if (value) {
//       // Find the selected item based on the provided value
//       const selectedOption = data.find(
//         (item) => (keyName ? item[keyName] : item._id) === value
//       );
//       if (selectedOption) {
//         setSelectedItem({
//           label: selectedOption.brand,
//           value: keyName ? selectedOption[keyName] : selectedOption._id,
//         });
//       }
//     } else {
//       setSelectedItem(null);
//     }
//   }, [value, data, keyName]);

//   return (
//     <>
//       <AsyncSelect
//         cacheOptions
//         onChange={(selectedOption) => handleFieldChange(selectedOption, { name })}
//         loadOptions={setItems}
//         defaultOptions
//         isClearable
//         name={name ?? "brand"}
//         value={selectedItem} // Use the selectedItem for displaying
//         placeholder={placeholder ?? "Select Brand"}
//         // onChange={(selected) => {
//         //   setSelectedItem(selected); // Update the selected item
//         //   handleFieldChange(selected); // Call the parent handler
//         // }}
//         className={error && "form-control ps-0 py-0 is-invalid"}
//         styles={{
//           menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
//           menu: (provided) => ({ ...provided, zIndex: 9999 }),
//           control: (provided) => ({
//             ...provided,
//             height: 42,
//             minHeight: 42,
//             ...style,
//           }),
//         }}
//       />
//     </>
//   );
// };

// SelectBrand.propTypes = {
//   handleFieldChange: PropTypes.func,
//   name: PropTypes.string,
//   error: PropTypes.any,
//   value: PropTypes.string, // Updated to match ID type
//   keyName: PropTypes.string,
//   style: PropTypes.object,
//   placeholder: PropTypes.string,
// };

// export default SelectBrand;

import { React, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import { get } from "helpers/apiConfig";

const SelectBrand = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([]);

  const setItems = async (inputValue) => {
    let response = await get("/admin/brand", {});
    let fetchData = response && response.data ? response.data.brands : [];

    if (fetchData.length > 0) {
      setData(fetchData);
    }

    // Filter brands based on input value
    const filteredData = fetchData.filter((item) =>
      item.brand.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filteredData.map((item) => ({
      label: item.brand,
      value: keyName ? item[keyName] : item._id,
    }));
  };

  useEffect(() => {
    if (value) {
      const selectedOption = data.find(
        (item) => (keyName ? item[keyName] : item._id) === value
      );
      if (selectedOption) {
        setSelectedItem({
          label: selectedOption.brand,
          value: keyName ? selectedOption[keyName] : selectedOption._id,
        });
      }
    } else {
      setSelectedItem(null);
    }
  }, [value, data, keyName]);

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={setItems}
      onChange={(selectedOption) => handleFieldChange(selectedOption, { name })}
      defaultOptions
      isClearable
      name={name ?? "brand"}
      value={selectedItem}
      placeholder={placeholder ?? "Select Brand"}
      className={error && "form-control ps-0 py-0 is-invalid"}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          height: 42,
          minHeight: 42,
          ...style,
        }),
      }}
    />
  );
};

SelectBrand.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.string,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectBrand;

