import BarCharts from "components/common/Charts/BarCharts";
import LineCharts from "components/common/Charts/LineCharts";
import PiCharts from "components/common/Charts/PiCharts";
import { apiCall } from "helpers/apiCalls";
import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import {
  FaBoxOpen,
  FaMoneyCheck,
  FaRegUserCircle,
  FaUserTie,
} from "react-icons/fa";
import { MdOutlineFeaturedVideo } from "react-icons/md";
import CountUp from "react-countup";
import { API } from "constants/apis";
import { getAdminRole } from "utils/adminUtils";
import LineGraph from "components/graph/LineGraph";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState({});

  //  const admin = getAdminDetails();
  const role = getAdminRole();

  const getDashboardData = async () => {
    const data = await apiCall({
      url: `/admin/dashboard?role=${role}`,
    });

    console.log(data?.data);

    setDashboardData(data?.data);
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <Container
        className="d-flex flex-column gap-3 custom-admin-dashboard"
        fluid
      >
        <Row className="mt-2 g-3">
          {role == "admin" ? (
            <Col lg="3" sm="6">
              <Card className="card-stats h-100">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning d-flex align-items-center">
                        <FaRegUserCircle
                          size={45}
                          style={{ color: "#c488a0" }}
                        />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Users</p>
                        <Card.Title as="h4" className="countdown">
                          <CountUp end={dashboardData?.totalUsers} />
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    {/* <i className="far fa-calendar-alt mr-1"></i> */}
                    {/* Last month */}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          ) : (
            <Col lg="3" sm="6">
              <Card className="card-stats h-100">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning d-flex align-items-center">
                        <FaBoxOpen size={45} style={{ color: "#90c488" }} />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Orders</p>
                        <Card.Title as="h4">
                          <CountUp end={dashboardData?.totalOrders} />
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    {/* <i className="far fa-calendar-alt mr-1"></i> */}
                    {/* Last day */}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          )}
          {role == "admin" ? (
            <Col lg="3" sm="6">
              <Card className="card-stats h-100">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning d-flex align-items-center">
                        <FaUserTie style={{ color: "#c4a688" }} size={40} />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Vendors</p>
                        <Card.Title as="h4">
                          <CountUp end={dashboardData?.totalVendors} />
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    {/* <i className="far fa-calendar-alt mr-1"></i> */}
                    {/* Last month */}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          ) : (
            <Col lg="3" sm="6">
              <Card className="card-stats h-100">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning d-flex align-items-center">
                        <FaMoneyCheck size={45} style={{ color: "#88a4c4" }} />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Payment</p>
                        <Card.Title as="h4">
                          <span className="me-2">£</span>
                          <CountUp end={dashboardData?.totalAmount} />
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="far fa-clock-o mr-1"></i>
                    {/* In the last hour */}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          )}
          {role == "admin" ? (
            <Col lg="3" sm="6">
              <Card className="card-stats h-100">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning d-flex align-items-center">
                        <FaBoxOpen size={45} style={{ color: "#90c488" }} />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Orders</p>
                        <Card.Title as="h4">
                          <CountUp end={dashboardData?.totalOrders} />
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    {/* <i className="far fa-calendar-alt mr-1"></i> */}
                    {/* Last day */}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          ) : (
            <Col lg="3" sm="6">
              <Card className="card-stats h-100">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning d-flex align-items-center">
                        <FaMoneyCheck size={45} style={{ color: "#88a4c4" }} />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Admin Commision</p>
                        <Card.Title as="h4">
                          <span className="me-2">£</span>
                          <CountUp end={dashboardData?.adminFee} />
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="far fa-clock-o mr-1"></i>
                    {/* In the last hour */}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          )}
          {role == "admin" ? (
            <Col lg="3" sm="6">
              <Card className="card-stats h-100">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning d-flex align-items-center">
                        <FaMoneyCheck size={45} style={{ color: "#88a4c4" }} />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Payments Received</p>
                        <Card.Title as="h4">
                          <span className="me-2">£</span>
                          <CountUp end={dashboardData?.totalOrderAmount} />
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="far fa-clock-o mr-1"></i>
                    {/* In the last hour */}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          ) : (
            <Col lg="3" sm="6">
              <Card className="card-stats h-100">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning d-flex align-items-center">
                        <FaMoneyCheck size={45} style={{ color: "#88a4c4" }} />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Net Amount</p>
                        <Card.Title as="h4">
                          <span className="me-2">£</span>
                          <CountUp end={dashboardData?.netAmount} />
                        </Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="far fa-clock-o mr-1"></i>
                    {/* In the last hour */}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          )}
        </Row>
        <Row className="g-3">
          <Col md="8" className="flex-fill">
            <Card className="h-100">
              <Card.Header>
                <Card.Title as="h4">Sales Overview</Card.Title>
                <p className="card-category">Last 1 Year</p>
              </Card.Header>
              <Card.Body>
                {/* <div className="ct-chart" id="chartActivity"> */}
                <LineGraph data={dashboardData?.monthlySalesData} />
                {/* </div> */}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="d-flex">
            <Card className="flex-fill">
              <Card.Header>
                <Card.Title as="h4">Top Selling Products</Card.Title>
              </Card.Header>
              <Card.Body>
                {dashboardData?.topSellingProducts?.length > 0 ? (
                  dashboardData.topSellingProducts.map(
                    (product) =>
                      product._id && (
                        <Card key={product._id} className="mb-3 shadow-sm">
                          <Card.Body className="d-flex align-items-center">
                            <img
                              src={`${IMAGE_URL}${product.images[0]}`}
                              alt={product.title}
                              className="me-3"
                              style={{
                                width: "70px",
                                height: "70px",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                            <div>
                              <h6 className="mb-1">{product.title}</h6>
                              <p
                                className="mb-1 text-muted"
                                style={{ fontSize: "0.85rem" }}
                              >
                                {product.description.substring(0, 15)}...
                              </p>
                              <p className="mb-0">
                                <strong>Quantity:</strong> {product.quantity}
                              </p>
                            </div>
                          </Card.Body>
                        </Card>
                      )
                  )
                ) : (
                  <p className="text-muted">No top-selling products found.</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
