import SelectNationality from "components/common/Form/SelectNationality";
import Loading from "components/common/LoadingScreen/Loading";
import FilterButton from "components/common/filter/FilterButton";
import { apiCall } from "helpers/apiCalls";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import removeRefData from "helpers/removeRefData";
import TablePageination from "modules/common/TablePageination";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Image, Modal, Table } from "react-bootstrap";
import { FaRegFileExcel, FaUserEdit } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { Outlet } from "react-router";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import "./VendorsTable.scss";
import axios from "axios";
import { getToast } from "modules/common/toast";
import { utils, write } from "xlsx";
import { saveAs } from "file-saver";
import { Switch } from "antd";
import { put } from "helpers/apiConfig";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";
import placeholder1 from "../../../assets/images/placeholder/placeholder1.jpg";

const VendorsTable = () => {
  const [agentsList, setAgentsList] = useState({});
  const [data, setData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const [showBankModal, setShowBankModal] = useState(null);
  const [showFieldList, setShowFieldList] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const startIndex = 0;
  // (agentsList?.meta?.current_page - 1) * agentsList?.meta?.per_page;
  const filterItems = queryParams.get("filters");
  const [filters] = useState({
    nationality_id: {
      label: "Nationality",
      operators: [{ name: "Equal", value: "=" }],
      Element: (item) =>
        item && item.operator === "=" ? (
          <div className="w-100">
            <SelectNationality
              value={item.value_ref}
              name={item.name}
              handleFieldChange={item.handleFieldChange}
            />
          </div>
        ) : (
          "STRING"
        ),
    },
    gender: {
      label: "Gender",
      operators: [{ name: "Equal", value: "=" }],
      Element: (item) => (item && item.operator === "=" ? "SELECT" : "STRING"),
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Other", value: "other" },
      ],
    },
  });

  const getData = async (page = 1) => {
    setIsLoading(true);
    // let nationality_id;
    // let gender;

    // let filterActive = filterItems
    //   ? removeRefData(createFilterArray(filterItems), "array")
    //   : null;

    // filterActive?.map((item) => {
    //   if (item.column === "nationality_id") {
    //     nationality_id = item.value;
    //   }
    // });

    // filterActive?.map((item) => {
    //   if (item.column === "gender") {
    //     gender = item.value;
    //   }
    // });

    const data = await apiCall({
      url: `/admin/vendors?search=${searchValue}`,
      params: {
        page: page,
        // role: "agent",
        // nationality_id: nationality_id ?? null,
        // gender: gender ?? null,
        // username: searchValue,
      },
    });

    setData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, filterItems]);

  const handleModal = (data) => {
    setShowModal(data);
  };

  const handlViewBankDetails = (data) => {
    setShowBankModal(data);
  };
  const handlViewFieldList = (id) => {
    setShowFieldList(id);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      getData();
    }
  };

  const toggleStatus = async (id) => {
    axios
      .get(`user/toggle-status/${id}`)
      .then((response) => {
        // console.log(response?.data);
        if (response.data.success === true) {
          if (response?.data?.data.status == 0) {
            getToast("Deactivated", "error");
          } else {
            getToast("Activated", "success");
          }

          // getData()
          getData(pageNumber);
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          onSessionOut
        ) {
          onSessionOut();
        }
      });
  };

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(agentsList.data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, `${"agent-list"}.xlsx`);
  };

  const handleVerifyToggle = async (checked, id) => {
    try {
      await put(`/admin/vendor/${id}/verify`, {
        adminVerified: checked,
      });

      const updatedVendors = data?.data.map((vendor) =>
        vendor._id === id ? { ...vendor, adminVerified: checked } : vendor
      );

      setData({
        ...data,
        data: updatedVendors,
      });
    } catch (error) {
      console.error(
        "There was an error updating the verification status!",
        error
      );
    }
  };

  return (
    <>
      <Outlet context={[getData, pageNumber]} />
      {!isLoading ? (
        <Card className="rounded-0 border-0 h-100 w-100 d-inline-block overflow-auto">
          <Card.Header className="p-2">
            <div className="d-flex justify-content-between">
              <span>
                {/* <FilterButton
                  className="me-1 d-flex justify-content-end border"
                  filters={filters}
                  url="agents"
                  variant="light"
                  showText
                /> */}
                <span className="search-box">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="border"
                    value={searchValue}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterKeyPress}
                  />
                  <FaSearch className="search-icon" />
                </span>
              </span>

              <span className="">
                <Button
                  className="rounded-0"
                  size="sm"
                  as={Link}
                  to={`add?${createSearchParams({
                    ...allQueryParams,
                  })}`}
                >
                  <MdAdd size={18} />
                </Button>
              </span>
            </div>
          </Card.Header>
          <Card.Body className="p-0 h-100 d-flex flex-column border-top">
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Nunber</th>
                  <th>Company Name</th>
                  <th>Business Name</th>
                  <th>Registration Number</th>
                  <th>Registration Date</th>
                  <th>Document Type</th>
                  <th>Document Number</th>
                  <th>Region</th>
                  <th>Share Code</th>
                  <th>DOB</th>
                  <th title="This feature is for vendors outside the UK. Admin manages order statuses.">
                    International Vendor
                  </th>
                  <th>Minimum Order Amount for Commission</th>
                  <th>Commission Percentage</th>
                  <th>Verified</th>
                  <th>Action</th>
                </tr>
              </thead>
              {console.log(Object.keys(data).length > 0, data?.data)}
              <tbody>
                {Object.keys(data).length > 0 && data?.data?.length > 0 ? (
                  data?.data?.map((user, key) => {
                    // console.log(user);

                    return (
                      <tr key={key}>
                        <td>{startIndex + key + 1}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Image
                              src={
                                user?.avatar
                                  ? IMAGE_URL + user?.avatar
                                  : placeholder1
                              }
                              roundedCircle
                              width={30}
                              height={30}
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {user?.first_name + " " + user?.last_name}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>{user?.email}</td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {user?.countryCode ? `+${user?.countryCode} ` : ""}
                          {user?.phone_number ?? "-"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {user?.companyName ?? "-"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {user?.businessName ?? "-"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {user?.registrationNumber ?? "-"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {user?.registrationDate?.slice(0, 10) ?? "-"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {user?.verified_document_details
                            ? user?.verified_document_type
                            : "-"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {user?.verified_document_details ?? "-"}
                        </td>
                        <td>{user?.regionDetails?.name}</td>
                        <td>{user?.shareCode ?? "-"}</td>
                        <td style={{ whiteSpace: "nowrap" }}>{user?.dob ?? "-"}</td>
                        <td>
                          {user?.isInternationalVendor ? "✅ Yes" : "❌ No"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {user?.minimumOrderAmountForCommission ?? "-"}
                        </td>{" "}
                        <td style={{ whiteSpace: "nowrap" }}>
                          {user?.commissionPercentage ?? "-"}
                        </td>
                        {/* <td>
                          <a
                            className="field-list"
                            onClick={() => handlViewFieldList(user?.id)}
                            href="#"
                          >
                            View
                          </a>
                        </td>
                        <td>
                          <a
                            className="field-list"
                            onClick={() => handlViewBankDetails(user)}
                            href="#"
                          >
                            View
                          </a>
                        </td> */}
                        <td>
                          <Switch
                            checked={user?.adminVerified}
                            onChange={(checked) =>
                              handleVerifyToggle(checked, user?._id)
                            }
                          />
                        </td>
                        <td className="d-flex">
                          <Button
                            size="sm"
                            variant="warning"
                            as={Link}
                            className="me-2"
                            to={`edit/${user?._id}`}
                          >
                            <FaUserEdit size={18} />
                          </Button>
                          {/* {user?.status === 0 ? (
                            <StatusButton
                              onPress={() => toggleStatus(user?.id)}
                              bg="success"
                            />
                          ) : (
                            <StatusButton
                              onPress={() => toggleStatus(user?.id)}
                              bg="danger"
                            />
                          )} */}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={11}>
                      <span className="d-flex justify-content-center">
                        No Data Found &#x1F609;
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer className="border p-0 mt-auto">
            <TablePageination
              data={data}
              url="/vendors"
              search={allQueryParams}
            />
          </Card.Footer>
        </Card>
      ) : (
        <Loading />
      )}

      <BankDetailsModal
        show={showBankModal != null ? true : false}
        handleClose={() => setShowBankModal(null)}
        userId={showBankModal?.id}
      />
    </>
  );
};

export default VendorsTable;

const StatusButton = ({ bg, onPress }) => {
  return (
    <Button
      onClick={onPress}
      variant={bg}
      style={{
        // backgroundColor: bg == "success" ? "green" : "red",
        borderWidth: 0,
        fontSize: 11,
        width: 80,
      }}
    >
      {bg == "success" ? "Activate" : "Deactivate"}
    </Button>
  );
};

const BankDetailsModal = ({ show, handleClose, userId }) => {
  const [showModal, setShowModal] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);

  const getData = () => {
    axios({
      method: "GET",
      url: `user/bank-accounts?user_id=${userId}`,
      data: {},
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(userId, res?.data?.data?.data);
        setBankDetails(res?.data?.data?.data);
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    getData();
  }, [show]);

  const handleAddBankModal = () => {
    setShowModal(true);
  };

  return (
    <div>
      <Modal className="field-modal-container" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
          <span className="">
            <Button
              onClick={() => handleAddBankModal()}
              className="rounded-0"
              size="sm"
            >
              <MdAdd size={18} />
            </Button>
          </span>
        </Modal.Header>
        <Modal.Body>
          <table className="field-modal-table table table-bordered">
            <tbody>
              <tr>
                <th>#</th>
                <th>Id</th>
                <th>Bank Name</th>
                <th>Account Number</th>
                <th>Account Holder Name</th>
                <th>Branch Name</th>
              </tr>
              {bankDetails?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.id}</td>
                  <td>{item?.bank_name}</td>
                  <td>{item?.account_number}</td>
                  <td>{item?.account_holder_name}</td>
                  <td>{item?.branch_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ height: 30 }} />
        </Modal.Body>
      </Modal>
      <AddBank
        show={showModal}
        handleRefresh={getData}
        handleClose={() => setShowModal(false)}
        id={userId}
      />
    </div>
  );
};

const AddBank = ({ show, handleClose, id, handleRefresh }) => {
  const [bankDetails, setBankDetails] = useState({
    user_id: id,
    bank_name: "",
    account_number: "",
    account_holder_name: "",
    branch_name: "",
  });
  const [formError, setFormError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "account_number") {
      if (!/^\d*$/.test(value)) {
        setFormError({ account_number: "Please enter numbers only." });
        return;
      } else {
        setFormError({ account_number: "" });
      }
    }
    setBankDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    axios({
      method: "POST",
      url: "user/add-bank-details",
      data: { ...bankDetails, user_id: id },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          setBankDetails({
            user_id: id,
            bank_name: "",
            account_number: "",
            account_holder_name: "",
            branch_name: "",
          });
          getToast(res.data.message, "success");
          handleRefresh();
          handleClose();
        }
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data.message) {
          getToast(error.response.data.message, "error");

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          getToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
      });
  };

  return (
    <Modal className="field-modal-container" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Bank Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="bankName">
            <Form.Label>Bank Name</Form.Label>
            <Form.Control
              type="text"
              name="bank_name"
              value={bankDetails.bank_name}
              onChange={handleChange}
              isInvalid={!!formError.bank_name}
            />
            <Form.Control.Feedback type="invalid">
              {formError.bank_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="accountNumber">
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              type="text"
              name="account_number"
              value={bankDetails.account_number}
              onChange={handleChange}
              isInvalid={!!formError.account_number}
            />
            <Form.Control.Feedback type="invalid">
              {formError.account_number}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="accountHolderName">
            <Form.Label>Account Holder Name</Form.Label>
            <Form.Control
              type="text"
              name="account_holder_name"
              value={bankDetails.account_holder_name}
              onChange={handleChange}
              isInvalid={!!formError.account_holder_name}
            />
            <Form.Control.Feedback type="invalid">
              {formError.account_holder_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="branchName">
            <Form.Label>Branch Name</Form.Label>
            <Form.Control
              type="text"
              name="branch_name"
              value={bankDetails.branch_name}
              onChange={handleChange}
              isInvalid={!!formError.branch_name}
            />
            <Form.Control.Feedback type="invalid">
              {formError.branch_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Button className="mt-3" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
