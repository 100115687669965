import React, { useContext, useState } from "react";
import { Card, Form, InputGroup, Button } from "react-bootstrap";
import { Col, Row } from "react-bootstrap/esm";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaLock,
  FaTwitterSquare,
  FaUser,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { loginFormKeys } from "helpers/formKeys";
import formDataHandler from "helpers/formDataHandler";
import { getToast } from "modules/common/toast";
import axios from "axios";
import { AuthWizardContext } from "context/Context";
import "./LoginForm.scss";
import { post } from "helpers/apiConfig";
import { API } from "constants/apis";

const LoginForm = () => {
  const [formData, setFormData] = useState(loginFormKeys);
  const [errors, setErrors] = useState({});
  const [authenticationLoading, setAuthenticationLoading] = useState(false);
  const { dispatch } = useContext(AuthWizardContext);

  const handleFieldChange = (e) =>
    setFormData(formDataHandler(e, null, formData));

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    if (!formData.email || !formData.password)
      return getToast(
        `Invalid request, ${
          !formData.email && !formData.password
            ? "email and password"
            : !formData.email
            ? "email"
            : "password"
        } is required.`,
        "error"
      );
    setAuthenticationLoading(true);
    setFormData({ ...formData, role: "vendor" });

    post("/vendor/login", formData)
      .then((response) => {
        console.log(response?.data);

        if (response.data.success !== true)
          return getToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );

        const userData = {
          ...response.data.user,
          lockScreen: false,
        };

        // save user data with token to localStorage
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("LOGGED_USER", JSON.stringify(userData));

        // update auth context
        dispatch({ type: "UPDATE", payload: { ...userData } });

        getToast(`Successfully logged in as ${userData.first_name}`, "success");
      })

      .catch((error) => {
        if (error?.code === "ERR_NETWORK") {
          getToast(
            "You're not connected! Ensure your internet connection is working!"
          );
        }
        if (error?.response?.data || error?.response?.data?.message) {
          if (error.response.status === 401) {
            getToast(error.response.data.message, "error");
          } else {
            return getToast(
              `Something went wrong, please refresh the page and try again.`,
              "error"
            );
          }
        }
        const validation_error =
          error?.response && error?.response?.data.message;

        validation_error && setErrors({ ...validation_error });
      });
  };

  return (
    <>
      <Card className="shadow form-container p-0" style={{ maxWidth: 600 }}>
        <Row>
          <Col md={12}>
            <Card className="border-0 shadow">
              <div className="d-flex justify-content-center">
                <Col md={12} className="text-center py-5">
                  <h3 className="font-weight-bold">Sign-in</h3>
                  <div className="d-flex justify-content-center">
                    <Form className="col-md-6" onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email Address</Form.Label>
                        <InputGroup className="rounded shadow py-1">
                          <InputGroup.Text className="border-0 bg-transparent px-2">
                            <FaUser size={25} />
                          </InputGroup.Text>
                          <Form.Control
                            className="border-0"
                            placeholder="Enter Email"
                            name="email"
                            onChange={handleFieldChange}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <InputGroup className="rounded shadow py-1">
                          <InputGroup.Text className="border-0 bg-transparent px-2">
                            <FaLock size={25} />
                          </InputGroup.Text>
                          <Form.Control
                            className="border-0"
                            type="password"
                            placeholder="Enter password"
                            name="password"
                            onChange={handleFieldChange}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        className="mb-3 justify-content-center"
                        controlId="forgot"
                      >
                        <div className="pb-3 pt-2">
                          <Button
                            type="submit"
                            className="rounded-pill p-2 px-4"
                            size="sm"
                            style={{
                              backgroundColor: "#9D2233",
                              borderColor: "#9D2233",
                            }}
                          >
                            Sign-in
                          </Button>
                        </div>
                      </Form.Group>
                      <div className="text-center">
                        <Link
                          to="/authenticate/register"
                          className="text-muted"
                        >
                          Don’t have an account? Register here
                        </Link>
                        
                      </div>
                      <div className="text-center">
                      <Link
                          to="/authenticate/forgot-password"
                          className="text-muted"
                        >
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </Col>
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default LoginForm;
