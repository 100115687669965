import { React, useState, useContext } from "react";
import axios from "axios";
import { Button, Card, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import { DeleteContext } from "context/Context";
import { getToast } from "modules/common/toast";

function DeleteWarning({
  show,
  onHide,
  id,
  fetchData,
  data,
  setKey,
  heading,
  message,
  url,
  type,
  setIsDeleted,
}) {
  const { config, dispatch } = useContext(DeleteContext);
  const [deleting, setDeleting] = useState(false);

  const submitDelete = (e) => {
    if (config.url) {
      onSubmit(e);
    } else {
      handleDelete();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (typeof config.onDelete === "function") {
      config.onDelete();
    }
    const configData = config;
    dispatch({ type: "RESET" });
    axios({
      method: config.method ? "put" : "delete",
      url: configData.url,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: "CONFIG",
            payload: config.method
              ? {
                  last_updated_item: configData.target
                    ? config.target
                    : undefined,
                }
              : {
                  last_deleted_item: configData.target
                    ? config.target
                    : undefined,
                },
          });

          if (
            typeof config.onSuccess === "function" &&
            response.data &&
            response.data.data
          ) {
            config.onSuccess(response.data.data);
          }
        } else {
          if (typeof config.onError === "function") {
            config.onError(response.data);
          }
        }

        getToast(
          response.data.success
            ? response.data.message
            : "Something went wrong, please refresh the page and try again.",
          response.data.success ? "success" : "error"
        );
      })
      .catch((error) => {
        if (typeof config.onError === "function") {
          config.onError(error);
        }

        getToast(
          error.response.data && error.response.data.message
            ? error.response.data.message
            : "Something went wrong, please refresh the page and try again.",
          "error"
        );
      });
  };

  const handleDelete = () => {
    setDeleting(true);
    if (id && type === "ATTACHMENT") {
      axios
        .delete(`${url}/${id}`)
        .then((res) => {
          if (res.data.success === true) {
            if (data.length > 1) {
              fetchData();
              onHide();
              setKey("attachments");
              setDeleting(false);
            } else {
              fetchData();
              onHide();
              setKey("overview");
              setDeleting(false);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            getToast(`Attachment not exist.`, "error");
          } else {
            getToast(
              `Something went wrong, please refresh the page and try again.`,
              "error"
            );
          }
          if (data.length > 1) {
            fetchData();
            onHide();
            setKey("attachments");
            setDeleting(false);
          } else {
            fetchData();
            onHide();
            setKey("overview");
            setDeleting(false);
          }
        });
    } else if (
      (id && type === "CUSTOMER") ||
      (id && type === "SALESMAN") ||
      (id && type === "ITEM") ||
      (id && type === "BRANCH") ||
      (id && type === "USER")
    ) {
      axios
        .delete(`${url}/${id}`)
        .then((response) => {
          if (response.data.success === true) {
            onHide();
          } else {
            getToast(response.data.message, "error");
            onHide();
          }
          if (type === "BRANCH") {
            setIsDeleted(true);
          }
          setDeleting(false);
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            getToast(error.response.data.message, "error");
          } else {
            getToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
          setDeleting(false);
          onHide();
        });
    }
  };

  const cancelModal = () => {
    if (typeof config.onCancel === "function") {
      config.onCancel();
    }
    if (config.url) {
      dispatch({ type: "RESET" });
    } else {
      onHide();
    }
  };

  return (
    <Modal
      show={config.url ? true : show ? show : false}
      size="md"
      className="modal-with-overlay"
    >
      <Card>
        <Card.Header className="text-center">
          <h5 className="fs--1 text-uppercase text-dark fw-bold">
            {config.title ? config.title : heading ? heading : "Delete"}
          </h5>
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center p-0">
          <p className="m-0 fs--1">
            {config.message
              ? config.message
              : message
              ? message
              : "Are you sure you want to delete this item?"}
          </p>
        </Card.Body>
        <Card.Footer className="d-flex gap-2 align-items-center justify-content-end mt-3">
          <Button
            variant={config.buttonText === "Enable" ? "success" : "danger"}
            style={{ boxShadow: "none" }}
            onClick={submitDelete}
            size="sm"
            disabled={deleting}
          >
            {config.buttonText
              ? config.buttonText
              : deleting
              ? "Deleting"
              : "Delete"}
          </Button>
          <Button
            variant={config.buttonText === "Enable" ? "danger" : "success"}
            style={{ boxShadow: "none" }}
            onClick={cancelModal}
            size="sm"
            disabled={deleting}
          >
            Cancel
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

DeleteWarning.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  id: PropTypes.any,
  fetchData: PropTypes.func,
  data: PropTypes.any,
  setKey: PropTypes.func,
  heading: PropTypes.string,
  message: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  setIsDeleted: PropTypes.func,
};

export default DeleteWarning;
