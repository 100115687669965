import axios from "axios";
// import "./App.css";
import Layout from "./layouts/Layout";
import "react-toastify/dist/ReactToastify.min.css";
import { AuthWizardContext } from "context/Context";
import { useContext } from "react";
import { getToast } from "modules/common/toast";
import { useNavigate } from "react-router";
import { useState } from "react";
import Error403Popup from "modules/common/Error/Error403Popup";


axios.interceptors.request.use(
  (config) => {
    // Perform any request-related modifications here, e.g., adding authentication headers
    config.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.crossOrigin = true;
    axios.defaults.crossDomain = true;
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["Accept"] = "application/json";
    const token =  localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
   
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

function App() {
  const [exceptionData, setExceptionData] = useState("");
  const [permission, setPermission] = useState(false);
  const [error, setError] = useState(false);
  let navigate = useNavigate();
  const { lockScreen } = useContext(AuthWizardContext);
  const currentUrl = window.location.href;

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let data = null;
      if (error && error.response) {
        if (error.response.data || error.response.status) {
          if (error.response.status === 401) {
            if (localStorage.getItem("LOGGED_USER")) {
              data = error.response.data.data.exception;
            } else {
              window.location.href = currentUrl;
            }
          } else if (error.response.status === 403) {
            setPermission(true);
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <Layout />
      <Error403Popup show={permission} onHide={() => setPermission(false)} />
    </>
  );
}

export default App;
