import {
    BlobServiceClient,
    StorageSharedKeyCredential,
  } from "@azure/storage-blob";
  
  const sasToken = process.env.REACT_APP_BLOB_SAS_TOKEN ?? "";
  const accountName = process.env.REACT_APP_BLOB_ACCOUNT_NAME ?? "";
  
  // export const uploadImageToAzure = async (file, containerName = "images") => {
  
  //   try {
  //     const blobSasUrl = `https://ddotstaticdev.blob.core.windows.net?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-02-11T12:57:33Z&st=2024-11-26T04:57:33Z&spr=https&sig=w2dzMj1PuqCq9U8HeVPsyKeaMoyZH4nze%2BV0mdTc6Bk%3D`;
  //     // const blobSasUrl = `https://${accountName}.blob.core.windows.net?${sasToken}`;
  //     const blobServiceClient = new BlobServiceClient(blobSasUrl);
  //     const containerClient = blobServiceClient.getContainerClient(containerName);
  
  //     const blobName = `${Date.now()}-${file.name}`;
  //     const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  
  //     // Set Content-Type explicitly
  //     const options = {
  //       blobHTTPHeaders: {
  //         blobContentType: file.type, // Set the Content-Type (e.g., "image/jpeg")
  //       },
  //     };
  
  //     // Upload file
  //     const uploadResponse = await blockBlobClient.uploadData(file, options);
  //     console.log("Upload successful:", uploadResponse);
  
  //     // Return URL of uploaded file
  //     const uploadedBlobUrl = `${
  //       blobSasUrl.split("?")[0]
  //     }/${containerName}/${blobName}`;
  //     console.log("File URL:", uploadedBlobUrl);
  
  //     return uploadedBlobUrl;
  //   } catch (error) {
  //     console.error("Error uploading image:", error);
  //     // throw error;
  //     return true
  //   }
  // };
  
  export const uploadImageToAzure = async (file, containerName = "images") => {
    try {
      const blobSasUrl = `https://ddotstaticdev.blob.core.windows.net/${containerName}?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-02-11T12:57:33Z&st=2024-11-26T04:57:33Z&spr=https&sig=w2dzMj1PuqCq9U8HeVPsyKeaMoyZH4nze%2BV0mdTc6Bk%3D`;
  
      const formData = new FormData();
      formData.append("file", {
        uri: file.uri, // Ensure 'file.uri' is valid
        name: `${Date.now()}-${file.name}`, // Unique filename
        type: file.type || "image/jpeg", // Default to JPEG if type is missing
      });
  
      const response = await fetch(blobSasUrl, {
        method: "PUT", // Azure requires PUT for direct upload
        body: formData,
        headers: {
          "Content-Type": file.type || "image/jpeg",
        },
      });
  
      if (!response.ok) {
        throw new Error(`Upload failed: ${response.statusText}`);
      }
  
      const uploadedBlobUrl = `${blobSasUrl.split("?")[0]}/${formData.get("file").name}`;
      console.log("File URL:", uploadedBlobUrl);
      return uploadedBlobUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null; // Return null to indicate failure
    }
  };
  
  // Remove Image Function
  export const removeImageFromAzure = async (blobUrl, containerName = "images") => {
  
    console.log("deleteImageFromAzure", blobUrl, containerName);
    
    try {
      const blobSasUrl = `https://ddotstaticdev.blob.core.windows.net?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2027-02-11T12:57:33Z&st=2024-11-26T04:57:33Z&spr=https&sig=w2dzMj1PuqCq9U8HeVPsyKeaMoyZH4nze%2BV0mdTc6Bk%3D`;
  
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      const containerClient = blobServiceClient.getContainerClient(containerName);
  
      // Extract the blob name from the URL
      const blobName = blobUrl.split(`/${containerName}/`)[1];
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  
      // Delete blob
      const deleteResponse = await blockBlobClient.delete();
      console.log("Blob deleted successfully:", deleteResponse);
  
      return true;
    } catch (error) {
      console.error("Error deleting image:", error);
      // throw error;
      return true;
    }
  };
  