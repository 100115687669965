import SelectNationality from "components/common/Form/SelectNationality";
import Loading from "components/common/LoadingScreen/Loading";
import FilterButton from "components/common/filter/FilterButton";
import { apiCall } from "helpers/apiCalls";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import removeRefData from "helpers/removeRefData";
import TablePageination from "modules/common/TablePageination";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Image,
  Modal,
  // Pagination,
  Table,
} from "react-bootstrap";
import { FaEdit, FaRegFileExcel, FaUserEdit } from "react-icons/fa";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { Outlet, useNavigate } from "react-router";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import "./OrdersTable.scss";
import axios from "axios";
import { getToast } from "modules/common/toast";
import { utils, write } from "xlsx";
import { saveAs } from "file-saver";
import { API } from "constants/apis";
import { get } from "helpers/apiConfig";
import { productImageURL } from "helpers/apiConfig";
import { remove } from "helpers/apiConfig";
import { List, notification, Pagination, Select } from "antd";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";
import { Modal as ConfirmDialog } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getAdminRole } from "utils/adminUtils";
import { put } from "helpers/apiConfig";
import { getAdminDetails } from "utils/adminUtils";

const NewOrders = () => {
  const [agentsList, setAgentsList] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();

  const role = getAdminRole();
  const loggedUser = getAdminDetails();

  const [showSizeDetails, setSizeDetails] = useState(null);
  const [sizesModalVisible, setSizesModalVisible] = useState(false);
  const [selectedProductSizes, setSelectedProductSizes] = useState([]);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(2);

  const [userModal, setUserModal] = useState(null);

  const allQueryParams = Object.fromEntries([...queryParams]);
  const startIndex = 0;

  const [expanded, setExpanded] = useState({});

  //new
  const [orders, setOrders] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const [editingOrder, setEditingOrder] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  // const [form] = Form.useForm();
  const [itemForms, setItemForms] = useState(new Map());
  let token = localStorage.getItem("token");

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  const filterItems = queryParams.get("filters");

  const [data, setData] = useState();
  const getData = async (page = 1) => {
    try {
      console.log(role);

      setIsLoading(true);
      const url = `/admin/new-orders?search=${searchValue}`;
      let response;
      response = await get(url);
      console.log(response?.data);
      if (response.data.success) {
        setData(response.data.orders);
      }
      setIsLoading(false);
    } catch (e) {
      setData([]);
      console.log(e.response?.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, role]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      getData(1);
      setPageNumber(1);
    }
  };

  const handleCloseSizesModal = () => {
    setSizesModalVisible(false);
    setSizeDetails(null);
  };

  /// new
  const handleEdit = (order) => {
    setEditingOrder(order);
    // form.setFieldsValue(order);
    setDrawerVisible(true);
  };
  const onClose = () => {
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    handleStatusUpdate(editingOrder?._id, values?.status);
    setEditingOrder(null);
    onClose();
  };

  const handleStatusUpdate = async (id, status) => {
    try {
      await put(`/order/status/${id}?token=${token}`, { status });

      const updatedOrders = orders.map((order) =>
        order._id === id ? { ...order, status: status } : order
      );
      setOrders(updatedOrders);

      notification.success({
        message: "Success",
        description: `Order status updated to ${status}`,
        placement: "topRight",
      });
    } catch (error) {
      console.error("There was an error updating the order status!", error);
      notification.error({
        message: "Error",
        description: "There was an error updating the order status.",
        placement: "topRight",
      });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filteredOrders = orders.filter(
      (order) =>
        order._id.toLowerCase().includes(query) ||
        order.user?.first_name?.toLowerCase().includes(query)
    );
    setOrders(filteredOrders);
  };

  const showItems = (items) => {
    setCurrentItems(items);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleItemStatusUpdate = async (itemId, trackingStatus) => {
    try {
      const orderId = editingOrder._id;
      await put(`/order/product-tracking-status/${orderId}?token=${token}`, {
        productId: itemId,
        trackingStatus,
      });

      const updatedOrders = orders.map((order) =>
        order._id === orderId
          ? {
              ...order,
              items: order.items.map((item) =>
                item._id === itemId
                  ? { ...item, trackingStatus: trackingStatus }
                  : item
              ),
            }
          : order
      );
      setOrders(updatedOrders);

      notification.success({
        message: "Success",
        description: `Item tracking status updated to ${trackingStatus}`,
        placement: "topRight",
      });
    } catch (error) {
      console.error(
        "There was an error updating the item tracking status!",
        error
      );
      notification.error({
        message: "Error",
        description: "There was an error updating the item tracking status.",
        placement: "topRight",
      });
    }
  };

  const handleCustomerDetails = (user) => {
    console.log(user);

    setSelectedCustomer(user);
    setCustomerModalVisible(true);
  };

  const handleCustomerModalClose = () => {
    setCustomerModalVisible(false);
  };

  const handlePrintPDF = () => {
    if (!selectedCustomer) return;

    const doc = new jsPDF();
    console.log();

    // Add Customer Details to PDF
    doc.setFontSize(16);
    doc.text("Customer Details", 10, 10);
    doc.setFontSize(12);
    doc.text(
      `Full Name: ${selectedCustomer.first_name} ${selectedCustomer.last_name}`,
      10,
      20
    );
    doc.text(`Phone: ${selectedCustomer.address?.phone_no}`, 10, 40);

    const address = selectedCustomer.address
      ? `${selectedCustomer.address.address_name}, ${
          selectedCustomer.address.house_number
        }, ${selectedCustomer.address.street_name}, ${
          selectedCustomer.address.locality_name || ""
        }, ${selectedCustomer.address.town || ""}, ${
          selectedCustomer.address.postcode
        }`
      : "No address available";

    doc.text(`Address: ${address}`, 10, 50);

    doc.save("Customer_Details.pdf");
  };

  const handleUserClick = (data) => {
    setUserModal({ ...data?.user, address: data?.shippingAddress });
  };

  return (
    <>
      <Outlet context={[getData, pageNumber]} />
      {!isLoading ? (
        <Card className="rounded-0 border-0 h-100 w-100 d-inline-block overflow-auto">
          <Card.Header className="p-2">
            <div className="d-flex justify-content-between">
              <span>
                <span className="search-box">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="border"
                    value={searchValue}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterKeyPress}
                  />
                  <FaSearch className="search-icon" />
                </span>
              </span>
            </div>
          </Card.Header>
          <Card.Body className="p-0 h-100 d-flex flex-column border-top">
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Order Id</th>
                  <th>Customer Name</th>
                  <th>Cart Amount </th>
                  <th>Discount</th>
                  <th>Net Amount </th>
                  <th>Commission Applicable</th>
                  <th>Commission Amount</th>
                  <th>Placed Date</th>
                  <th>Expected Date</th>
                  <th>Items & Status</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data?.map((order, index) => {

                    let commissionAmount = 0;
                    let isCommissionApplicable =
                      order?.items[0]?.minimumOrderAmountForCommission <=
                      order?.totalAmount - order?.totalDiscount;

                    if (isCommissionApplicable) {
                      commissionAmount =
                        ((order?.totalAmount -
                          order?.totalDiscount -
                          order?.items[0]?.minimumOrderAmountForCommission) *
                          order?.items[0]?.commissionPercentage) /
                        100;
                    }

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <span
                            style={{
                              backgroundColor: "yellow",
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            {order?._id}
                          </span>
                        </td>
                        <td>
                          {" "}
                          <span
                            onClick={() => handleUserClick(order)}
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            {order.user?.first_name}
                          </span>
                        </td>
                        <td>{order.totalAmount?.toFixed(2)}</td>
                        <td>{order?.totalDiscount?.toFixed(2)}</td>
                        <td>
                          {(order.totalAmount - order.totalDiscount)?.toFixed(
                            2
                          )}
                        </td>
                        <td> {isCommissionApplicable ? "✅ Yes" : "❌ No"}</td>
                        <td>
                          {isCommissionApplicable ? commissionAmount : "-"}
                        </td>
                        <td>{order.createdAt?.slice(0, 10)}</td>
                        <td>
                          {order?.items[0]?.expectedDeliveryDate?.slice(0, 10)}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          <Button
                            onClick={() => {
                              setEditingOrder(order);
                              showItems(order.items);
                            }}
                          >
                            View
                          </Button>
                        </td>
                        {console.log(loggedUser)}
                        {/* {loggedUser?.isInternationalVendor != true ? (
                          <td>
                            <Select
                              defaultValue={order.status}
                              style={{ width: 120 }}
                              onChange={(value) =>
                                handleStatusUpdate(order._id, value)
                              }
                            >
                              //'Order Placed','Pending', 'Packed', 'Shipped',
                              'Delivered', 'Cancelled'
                              <Option value="Pending">Pending</Option>
                              <Option value="Packed">Packed</Option>
                              <Option value="Shipped">Shipped</Option>
                              <Option value="Delivered">Delivered</Option>
                              <Option value="Cancelled">Cancelled</Option>
                            </Select>
                          </td>
                        ) : (
                          <td style={{ whiteSpace: "nowrap" }}>
                            {order.status}
                          </td>
                        )} */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={12}>
                      <span className="d-flex justify-content-center">
                        No Data Found &#x1F609;
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
          {/* {(data?.products?.length >= 10 || pageNumber !== 1) && (
            <Pagination
              current={pageNumber}
              pageSize={pageSize}
              total={pageSize + 1}
              onChange={handlePageChange}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            />
          )} */}
          <Card.Footer className="border p-0 mt-auto"></Card.Footer>
        </Card>
      ) : (
        <Loading />
      )}

      <UserModal
        show={userModal != null ? true : false}
        handleClose={() => setUserModal(null)}
        data={userModal}
      />
      <ConfirmDialog
        title="Order Items"
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        <List
          itemLayout="horizontal"
          dataSource={currentItems}
          renderItem={(item) => {
            if (!itemForms.has(item._id)) {
              // itemForms.set(item._id, Form.useForm()[0]);
            }

            console.log(item);

            return (
              <List.Item>
                <List.Item.Meta
                  title={item.product[0]?.title}
                  description={
                    <>
                      <div>Price: {item.price}</div>
                      <div>Description: {item.product[0]?.description}</div>
                      <div>
                        Tracking Status:
                        <Select
                          disabled={loggedUser?.isInternationalVendor == true}
                          defaultValue={item.trackingStatus}
                          onChange={(value) =>
                            handleItemStatusUpdate(item._id, value)
                          }
                          style={{ width: 120, marginLeft: 8 }}
                        >
                          <Option value="Order Confirmed">Order Confirmed</Option>
                          <Option value="Packed">Packed</Option>
                          <Option value="Shipped">Shipped</Option>
                          <Option value="Delivered">Delivered</Option>
                          <Option value="Cancelled">Cancelled</Option>
                        </Select>
                      </div>
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      </ConfirmDialog>
    </>
  );
};

export default NewOrders;

const StatusButton = ({ bg, onPress }) => {
  return (
    <Button
      onClick={onPress}
      variant={bg}
      style={{
        borderWidth: 0,
        fontSize: 11,
        width: 80,
      }}
    >
      {bg == "success" ? "Activate" : "Deactivate"}
    </Button>
  );
};

const UserModal = ({ show, handleClose, data }) => {
  return (
    <Modal className="field-modal-container" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="field-modal-table table table-bordered">
          <tbody>
            <tr>
              <th width={"50%"}>Name</th>
              <td>{data?.first_name + " " + data?.last_name}</td>
            </tr>

            <tr>
              <th>Phone Number</th>
              <td>{data?.phone_number ?? "--"}</td>
            </tr>

            <tr>
              <th>Email</th>
              <td>{data?.email ?? "--"}</td>
            </tr>
            <tr>
              <th>Address</th>
              <td>
                {data?.address
                  ? `${data?.address.house_number}, ${data?.address.street_name}, ${data?.address.locality_name}, ${data?.address.town}, ${data?.address.postcode}`
                  : "--"}
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ height: 30 }} />
      </Modal.Body>
    </Modal>
  );
};
