import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../LandingPage";
import AuthOutlet from "../modules/authentication/pages/AuthOutlet";
import LoginForm from "../modules/authentication/pages/login/LoginForm";
import { ToastContainer } from "react-toastify";
import Admin from "./Admin";
import Dashboard from "views/Dashboard";
import ValidateAuth from "helpers/ValidateAuth";
import RequireGuest from "helpers/RequireGuest";
import Registration from "../modules/authentication/pages/register/Registration";
import NotFound from "components/common/Pages/404/NotFound";
import DeleteContextProvider from "providers/DeleteContextProvider";

import ProdductsTable from "modules/Products/ProductsTable";
import ProductForm from "modules/Products/ProductsForm";
import Category from "modules/Settings/Category/Category";
import CategoryForm from "modules/Settings/Category/CategoryForm";
import SettingsOutlet from "modules/Settings/SettingsOutlet";
import SubCategoryForm from "modules/Settings/SubCategory/SubCategoryForm";
import SubCategory from "modules/Settings/SubCategory/SubCategory";
import Size from "modules/Settings/Size/Size";
import SizeForm from "modules/Settings/Size/SizeForm";
import Banner from "modules/Settings/Banner/Banner";
import BannerForm from "modules/Settings/Banner/BannerForm";
import VendorsTable from "modules/Users/Vendors/VendorsTable";
import VendorsForm from "modules/Users/Vendors/VendorsForm";
import CustomersTable from "modules/Users/Customer/CustomersTable";
import CustomerForm from "modules/Users/Customer/CustomerForm";
import ColorForm from "modules/Settings/Color/ColorForm";
import Color from "modules/Settings/Color/Color";
import CouponTable from "modules/Coupon/CouponTable";
import CouponForm from "modules/Coupon/CouponForm";
import Brand from "../modules/Settings/Brand/Brand";
import BrandForm from "modules/Settings/Brand/BrandForm";
import UpdateProfileForm from "modules/Settings/UpdateProfile/UpdateProfileFrom";
import ForgotPasswordForm from "modules/authentication/pages/ForgotPassword/ForgotPasswordForm";
import PrivacyPolicy from "modules/Link/PrivacyPolicy";
import DeleteAccountPage from "modules/Link/DeleteAccount";
import IndividualRegistration from "modules/authentication/pages/IndividualRegister/IndividualRegistration";
import OrdersTable from "modules/Orders/OrdersTable";
import NewOrders from "modules/Orders/NewOrders";
import IntenationalOrders from "modules/Orders/IntenationalOrders";
import PayoutOutlet from "modules/Payout/PayoutOutlet";
import Payout from "modules/Payout/PayoutList/Payout";
import PayoutSummary from "modules/Payout/PayoutSummary/PayoutSummary";
import DeletedProductTable from "modules/Products/DeletedProducts";

const Layout = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="delete-user-account" element={<DeleteAccountPage />} />
        <Route
          path="authenticate"
          element={
            <RequireGuest>
              <AuthOutlet />
            </RequireGuest>
          }
        >
          <Route index element={<LoginForm />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="register" element={<Registration />} />
          <Route
            path="individual-signup"
            element={<IndividualRegistration />}
          />
          <Route path="forgot-password" element={<ForgotPasswordForm />} />
        </Route>
        <Route
          path="/"
          element={
            <DeleteContextProvider>
              <ValidateAuth>
                <Admin />
              </ValidateAuth>
            </DeleteContextProvider>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="products" element={<ProdductsTable />}>
            <Route path="add" element={<ProductForm />} />
            <Route path="edit/:id" element={<ProductForm />} />
          </Route>
          <Route path="orders" element={<OrdersTable />} />
          <Route path="new-orders" element={<NewOrders />} />
          <Route path="international-orders" element={<IntenationalOrders />} />
          <Route path="customers" element={<CustomersTable />}>
            <Route path="add" element={<CustomerForm />} />
            <Route path="edit/:id" element={<CustomerForm />} />
          </Route>
          <Route path="vendors" element={<VendorsTable />}>
            <Route path="add" element={<VendorsForm />} />
            <Route path="edit/:id" element={<VendorsForm />} />
          </Route>
          <Route path="coupon" element={<CouponTable />}>
            <Route path="add" element={<CouponForm />} />
            <Route path="edit/:id" element={<CouponForm />} />
          </Route>
          <Route path="deleted-products" element={<DeletedProductTable />}>
          </Route>
          <Route path="deleted-products-admin" element={<DeletedProductTable />}/>
          {/* <Route path="customers" element={<CustomersTable />}>
            <Route path="add" element={<CustomersForm />} />
            <Route path="edit/:userId" element={<CustomersForm />} />
          </Route> */}
          {/* <Route path="players" element={<PlayersTable />}>
            <Route path="add" element={<PlayersForm />} />
            <Route path="edit/:userId" element={<PlayersForm />} />
          </Route> */}

          {/* <Route path="roles" element={<Roles />}>
            <Route path="add" element={<RolesForm />} />
            <Route path="edit/:roleId" element={<RolesForm />} />
          </Route> */}
          {/* <Route path="field-feature" element={<FieldFeature />}>
            <Route path="add" element={<FieldFeatureForm />} />
            <Route path="edit/:featureId" element={<FieldFeatureForm />} />
          </Route> */}
          {/* ------ all fields and approve button ------ */}
          {/* <Route path="/field-list" element={<Field />}></Route> */}
          {/* <Route path="/verification-pending-field-list" element={<VerificationPendingField />}></Route> */}
          {/* <Route path="/rejected-field-list" element={<RejectedField />}></Route> */}

          <Route path="payout" element={<PayoutOutlet />}>
            <Route path="list" element={<Payout />}>
              {/* <Route path="add" element={<PayoutForm />} />
              <Route path="edit/:payoutId" element={<PayoutForm />} /> */}
            </Route>
            {/* <Route path="summary" element={<PayoutSummary />}></Route>
            <Route path="history" element={<PayoutHistory />}></Route> */}
          </Route>
          <Route path="payment" element={<PayoutOutlet />}>
            <Route path="summary" element={<PayoutSummary />}>
              {/* <Route path="add" element={<PayoutForm />} />
              <Route path="edit/:payoutId" element={<PayoutForm />} /> */}
            </Route>
            {/* <Route path="summary" element={<PayoutSummary />}></Route>
            <Route path="history" element={<PayoutHistory />}></Route> */}
          </Route>
          <Route path="settings" element={<SettingsOutlet />}>
            <Route
              path="update-profile"
              element={<UpdateProfileForm />}
            ></Route>
            <Route path="main-category" element={<Category />}>
              <Route path="add" element={<CategoryForm />} />
              <Route path="edit/:id" element={<CategoryForm />} />
            </Route>
            <Route path="sub-category" element={<SubCategory />}>
              <Route path="add" element={<SubCategoryForm />} />
              <Route path="edit/:id" element={<SubCategoryForm />} />
            </Route>
            <Route path="sizes" element={<Size />}>
              <Route path="add" element={<SizeForm />} />
              <Route path="edit/:id" element={<SizeForm />} />
            </Route>
            <Route path="banner" element={<Banner />}>
              <Route path="add" element={<BannerForm />} />
              <Route path="edit/:id" element={<BannerForm />} />
            </Route>
            <Route path="color" element={<Color />}>
              <Route path="add" element={<ColorForm />} />
              <Route path="edit/:id" element={<ColorForm />} />
            </Route>
            <Route path="brand" element={<Brand />}>
              <Route path="add" element={<BrandForm />} />
              <Route path="edit/:id" element={<BrandForm />} />
            </Route>
            {/* <Route path="country" element={<Country />}>
              <Route path="add" element={<CountryForm />} />
              <Route path="edit/:countryId" element={<CountryForm />} />
            </Route>
            <Route path="nationality" element={<Nationality />}>
              <Route path="add" element={<NationalityForm />} />
              <Route path="edit/:nationId" element={<NationalityForm />} />
            </Route>
            <Route path="city" element={<City />}>
              <Route path="add" element={<CityForm />} />
              <Route path="edit/:cityId" element={<CityForm />} />
            </Route>
            <Route path="system" element={<SystemSettings />}>
              <Route path="add" element={<SystemSettingsForm />} />
              <Route path="edit/:settingsId" element={<SystemSettingsForm />} />
            </Route>
            <Route path="facility" element={<Facility />}>
              <Route path="add" element={<FacilityForm />} />
              <Route path="edit/:facilityId" element={<FacilityForm />} />
            </Route>
            <Route path="promo-code" element={<PromoCodes />}>
              <Route path="add" element={<PromoCodeForm />} />
              <Route path="edit/:facilityId" element={<PromoCodeForm />} />
            </Route>
            <Route path="match" element={<MatchSettings />}>
              <Route path="edit/:settingsId" element={<MatchSettingsForm />} />
            </Route>
            <Route path="app-page" element={<AppPages />}>
              <Route path="edit/:pageId" element={<AppPagesForm />} />
            </Route> */}
          </Route>
          {/* <Route path="subscription-plans" element={<SubscriptionPlans />}>
            <Route path="add" element={<SubscriptionPlansForm />} />
            <Route path="edit/:planId" element={<SubscriptionPlansForm />} />
          </Route> */}
          {/* <Route path="ads" element={<Ads />}>
            <Route path="add" element={<AdsForm />} />
          </Route> */}
          {/* <Route path="tutorials" element={<Tutorials />}>
            <Route path="add" element={<TutorialsForm />} />
            <Route path="edit/:tutorialId" element={<TutorialsForm />} />
          </Route> */}
          {/* <Route path="reports" element={<ReportOutlet />}>
            <Route index element={<Reports />} />
            <Route path="customer-report" element={<CustomerReport />} />
            <Route
              path="customer-transactions-report"
              element={<CustomerTransactionsReport />}
            />
            <Route path="agent-report" element={<AgentReport />} />
            <Route
              path="agent-transactions-report"
              element={<AgentTransactionReport />}
            />
            <Route path="payment-report" element={<PaymentReport />} />
          </Route> */}
          <Route path="404" element={<NotFound />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};

export default Layout;
