import SelectCountry from "components/common/Form/SelectCountry";
import Loading from "components/common/LoadingScreen/Loading";
import FilterButton from "components/common/filter/FilterButton";
import { DeleteContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import removeRefData from "helpers/removeRefData";
import TableHeaderCustom from "modules/common/TableHeaderCustom";
import TablePageination from "modules/common/TablePageination";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { Outlet } from "react-router";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";

import { Modal as ConfirmDialog } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getToast } from "modules/common/toast";
import { remove } from "helpers/apiConfig";

const Brand = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const { dispatch } = useContext(DeleteContext);
  const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";
  // const startIndex =
  //   (cityList?.meta?.current_page - 1) * cityList?.meta?.per_page;
  const filterItems = queryParams.get("filters");
  const [filters] = useState({
    name: {
      label: "Name",
      operators: [{ name: "Equal", value: "=" }],
      active: true,
    },
    country_id: {
      label: "Country",
      operators: [{ name: "Equal", value: "=" }],
      Element: (item) =>
        item && item.operator === "=" ? (
          <div className="w-100">
            <SelectCountry
              value={item.value_ref}
              name={item.name}
              handleFieldChange={item.handleFieldChange}
            />
          </div>
        ) : (
          "STRING"
        ),
    },
  });

  const handleDelete = (id) => {
    ConfirmDialog.confirm({
      title: "Delete  Brand",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this Brand?",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await remove(`/admin/brand/${id}`);
          if (res.data.success) {
            getToast("Brand deleted successfully", "success");
            const filteredData = data?.filter((item) => item._id !== id);
            setData(filteredData);
          }
        } catch (error) {
          console.error("Error deleting Brand:", error);
        }
      },
    });
  };

  const getData = async (page = 1) => {
    setIsLoading(true);

    const data = await apiCall({
      url: "/admin/brand",
    });

    setData(data.brands);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, filterItems]);

  return (
    <>
      <Outlet context={[getData, pageNumber]} />
      {!isLoading ? (
        <Card className="rounded-0 border-0 h-100 w-100 d-inline-block overflow-auto">
          <Card.Header className="p-2">
            <div className="d-flex justify-content-end">
              <span className="">
                <Button
                  className="rounded-0"
                  size="sm"
                  as={Link}
                  to={`add?${createSearchParams({
                    ...allQueryParams,
                  })}`}
                >
                  <MdAdd size={18} />
                </Button>
              </span>
            </div>
          </Card.Header>
          <Card.Body className="p-0  h-100 d-flex flex-column border-top">
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Brand Name</th>
                  <th>Description</th>
                  <th>logo</th>
                  <th>Website</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(data).length > 0 &&
                Object.keys(data).length > 0 ? (
                  data.map((item, key) => (
                    <tr key={key}>
                      <td width={"5%"}>{key + 1}</td>

                      <td>{item?.brand}</td>
                      <td>{item?.description}</td>
                      <td>
                        <img
                          src={item?.logo}
                          // className="img-fluid"
                          width="40"
                          height="40"
                          className="me-2"
                          alt="Product"
                        />
                      </td>
                      <td>
                        <a
                          href={item?.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item?.website}
                        </a>
                      </td>

                      <td className="">
                        <div className="d-flex flex-row h-100 align-items-center">
                          <Button
                            size="sm"
                            variant="warning"
                            className="me-2"
                            as={Link}
                            to={`edit/${item._id}?${createSearchParams({
                              ...allQueryParams,
                            })}`}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => {
                              handleDelete(item._id);
                              // getToast(
                              //   "Oops! This feature isn’t available right now. We’re working on it!",
                              //   "danger"
                              // );
                            }}
                          >
                            <FaTrash />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <span className="d-flex justify-content-center">
                        No Data Found &#x1F609;
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Card.Footer className="p-0 m-0 d-flex align-items-bottom mt-auto"></Card.Footer>
          </Card.Body>
        </Card>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Brand;
