import axios from "axios";
import SelectCity from "components/common/Form/SelectCity";
import SelectCountry from "components/common/Form/SelectCountry";
import SelectNationality from "components/common/Form/SelectNationality";
import SelectPlayerPosition from "components/common/Form/SelectPlayerPosition";
import ListLoading from "components/common/LoadingScreen/ListLoading";
import { apiCall } from "helpers/apiCalls";
import { registerFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import { getToast } from "modules/common/toast";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate, useOutletContext, useParams } from "react-router";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css';

const CustomerForm = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState();
  const [roles, setRoles] = useState({});
  const [formError, setFormError] = useState({});
  const [getData] = useOutletContext();
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (e, action) => {
    let newFormData = "";
    let value;
    if (action) {
      newFormData = {
        [action.name]: e ? e.value : null,
        [action.name + "_ref"]: e,
      };
    } else {
      if (e.target.name === "profile_picture") {
        value = e.target.files[0];
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    }
    setFormData({ ...formData, ...newFormData });
  };

  const getDataForm = async () => {
    setLoading(true);

    if (id) {
      let data = await apiCall({
        url: "/vendor/" + id,
      });

      

      if (data?.success) {
        setFormData({
         first_name: data?.data?.first_name,
         last_name: data?.data?.last_name,
         phone_number: data?.data?.phone_number,
         email: data?.data?.email,
         avatar: data?.data?.avatar,
         companyName:data?.data?.companyName,
         businessName:data?.data?.businessName,
         registrationNumber:data?.data?.registrationNumber,
         registrationDate:data?.data?.registrationDate,
         verified_document_type:data?.data?.verified_document_type,
         verified_document_details:data?.data?.verified_document_details,
         countryCode: data?.data?.countryCode,
        });
      }

      console.log("Loading", data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataForm();
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});

    if (id) {
      delete formData.password;
      delete formData.c_password;
    }

    roles?.filter((item) => {
      if (item.slug === "agent") formData.role_id = item.id;
    });

    let newFormData = setNewFormData(removeRefData(formData));
    axios({
      method: "POST",
      url: id ? "user/update/" + id : "user/register",
      data: newFormData,
      headers: {
        "Content-Type": id ? "application/json" : "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          setFormData({
            username: "",
            email: "",
            password: "",
            c_password: "",
            gender: "",
            dob: "",
            phone: "",
            role_id: "",
            name: "",
            primary_position: "",
            secondary_position: "",
            profile_picture: {},
          });
          getData();
          getToast(res.data.message, "success");
          navigate(-1);
        }
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data.message) {
          getToast(error.response.data.message, "error");

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          getToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
      });
  };

  return (
    <>
      <Offcanvas
        show
        onHide={() => navigate(-1)}
        placement="end"
        style={{ minWidth: "100%" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <FaUserPlus size={25} className="me-2" /> {id ? "Edit" : "Add"}{" "}
            Vendor
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!loading ? (
            <Form>
              <Row>
                <Col md={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">First Name</Form.Label>
                    <Form.Control
                     
                      name="first_name"
                      placeholder="First Name"
                      type="text"
                      value={formData?.first_name}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.first_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">Email</Form.Label>
                    <Form.Control
                     
                      placeholder="Email"
                      type="email"
                      name="email"
                      onChange={handleFieldChange}
                      value={formData?.email}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Company Name
                    </Form.Label>
                    <Form.Control
                     
                      placeholder="Company Name"
                      type="text"
                      name="companyName"
                      value={formData?.companyName}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.companyName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.companyName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      onChange={handleFieldChange}
                      value={formData?.last_name}
                      placeholder="Enter Last Name"
                      isInvalid={!!formError?.last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.last_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <PhoneInput
                      country={"gb"}
                      value={formData?.phone_number}
                      onChange={(phone, country) => {
                        console.log("Phone Number", phone, country);

                        setFormData({
                          ...formData,
                          phone_number: phone,
                          countryCode: country?.dialCode,
                        });
                      }}
                      inputClass="custom-input"
                      containerClass="w-100"
                      inputProps={{
                        name: "phone_number",
                        required: true,
                        className: "form-control",
                      }}
                      isValid={!formError?.phone_number}
                    />
                    {formError?.phone_number && (
                      <div className="invalid-feedback d-block">
                        {formError?.phone_number}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Business Name
                    </Form.Label>
                    <Form.Control
                     
                      placeholder="Business Name"
                      type="text"
                      name="businessName"
                      value={formData?.businessName}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.businessName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.businessName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Registration Number
                    </Form.Label>
                    <Form.Control
                     
                      placeholder="Registration Number"
                      type="text"
                      name="registrationNumber"
                      value={formData?.registrationNumber}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.registrationNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.registrationNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">
                      Registration Date
                    </Form.Label>
                    <Form.Control
                     
                      placeholder="Registration Date"
                      name="registrationDate"
                      type="text"
                      value={formData?.registrationDate}
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      onChange={handleFieldChange}
                      isInvalid={!!formError?.registrationDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.registrationDate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex mt-3">
                <Button
                  size="md"
                  className="ms-auto"
                  variant={id ? "primary" : "success"}
                  onClick={handleSubmit}
                >
                  {id ? "Update" : "Save"}
                </Button>
              </div>
            </Form>
          ) : (
            <ListLoading />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CustomerForm;
