import { AiFillCodeSandboxCircle, AiOutlineFileProtect } from "react-icons/ai";
import {
  FaBoxOpen,
  FaList,
  FaListAlt,
  FaOsi,
  FaRegistered,
  FaRegUserCircle,
  FaTags,
  FaTrademark,
  FaTrash,
  FaTrashAlt,
  FaUserEdit,
  FaUserTie,
} from "react-icons/fa";
import { GiSettingsKnobs, GiPencilRuler } from "react-icons/gi";
import {
  MdCategory,
  MdColorLens,
  MdGridOn,
  MdImage,
  MdOutlineCurrencyExchange,
  MdOutlinePayment,
  MdOutlineSettings,
  MdOutlineSummarize,
} from "react-icons/md";

const vendorRoute = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <FaOsi size={25} />,
    child: false,
  },
  {
    path: "/products",
    name: "Products",
    child: false,
    icon: <FaListAlt size={25} />,
  },

  {
    path: "/new-orders",
    name: "New Orders",
    child: false,
    icon: <FaBoxOpen size={25} />,
  },
  {
    path: "/orders",
    name: "All Orders",
    child: false,
    icon: <FaBoxOpen size={25} />,
  },

  {
    path: "/coupon",
    name: "Coupon",
    child: false,
    icon: <FaTags size={25} />,
  },
  {
    path: "/deleted-products",
    name: "Deleted Products",
    child: false,
    icon: <FaListAlt size={25} />,
  },
  {
    path: "/payment",
    name: "Payment",
    icon: <MdOutlineCurrencyExchange size={23} />,
    child: true,
    children: [
      {
        path: "/payment/summary",
        name: "Payment Summary",
        icon: <MdOutlineSummarize size={25} />,
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <MdOutlineSettings size={25} />,
    child: true,
    children: [
      {
        path: "/settings/update-profile",
        name: "Update Profile",
        child: false,
        icon: <FaUserEdit size={23} />,
      },
    ],
  },

  // {
  //   path: "/subscription-plans",
  //   name: "Subscription Plans",
  //   child: false,
  //   icon: <FaBoxOpen size={23} />,
  // },
  // {
  //   path: "/ads",
  //   name: "Ads",
  //   child: false,
  //   icon: <FaBuysellads size={23} />,
  // },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   child: false,
  //   icon: <AiOutlineFileProtect size={23} />,
  // },
];

const adminRoute = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <FaOsi size={25} />,
    child: false,
  },
  {
    path: "/products",
    name: "All Products",
    child: false,
    icon: <FaListAlt size={25} />,
  },
  {
    path: "/international-orders",
    name: "International Orders",
    child: false,
    icon: <FaBoxOpen size={25} />,
  },
  // {
  //   path: "/new-orders",
  //   name: "New Orders",
  //   child: false,
  //   icon: <FaBoxOpen size={25} />,
  // },
  {
    path: "/orders",
    name: "All Orders",
    child: false,
    icon: <FaBoxOpen size={25} />,
  },

  // {
  //   path: "/all-orders",
  //   name: "All Orders",
  //   child: false,
  //   icon: <FaBoxOpen size={25} />,
  // },
  {
    path: "/customers",
    name: "Customers",
    child: false,
    icon: <FaRegUserCircle size={25} />,
  },
  {
    path: "/vendors",
    name: "Vendors",
    child: false,
    icon: <FaUserTie size={25} />,
  },
  


  


  {
    path: "/payout",
    name: "Payout",
    icon: <MdOutlineCurrencyExchange size={23} />,
    child: true,
    children: [
      {
        path: "/payout/list",
        name: "Payout List",
        icon: <MdOutlinePayment size={25} />,
      },
      // {
      //   path: "/payout/summary",
      //   name: "Payout Summary",
      //   icon: <MdOutlineSummarize size={25} />,
      // },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <MdOutlineSettings size={25} />,
    child: true,
    children: [
      {
        path: "/settings/main-category",
        name: "Main Category",
        icon: <MdCategory size={25} />, // Icon for Main Category
      },
      {
        path: "/settings/sub-category",
        name: "Sub Category",
        icon: <MdGridOn size={25} />,
      },
      {
        path: "/settings/sizes",
        name: "Sizes",
        icon: <GiPencilRuler size={25} />, // Icon for Sizes
      },
      {
        path: "/settings/banner",
        name: "Banner",
        icon: <MdImage size={25} />, // Icon for Banner
      },
      {
        path: "/settings/color",
        name: "Color",
        icon: <MdColorLens size={25} />, // Icon for Banner
      },
      {
        path: "/settings/brand",
        name: "Brand",
        icon: <FaRegistered size={25} />, // Icon for Banner
      },
      // {
      //   path: "/settings/system-settings",
      //   name: "System Settings",
      //   icon: <GiSettingsKnobs size={25} />, // Icon for System Settings
      // },
    ],
  },

  {
    path: "/deleted",
    name: "Removed Items",
    icon: <FaTrashAlt size={23} />,
    child: true,
    children: [
      {
        path: "/deleted-products-admin",
        name: "Products",
        child: false,
        icon: <FaListAlt size={25} />,
      },
      // {
      //   path: "/payout/summary",
      //   name: "Payout Summary",
      //   icon: <MdOutlineSummarize size={25} />,
      // },
    ],
  },
  // {
  //   path: "/subscription-plans",
  //   name: "Subscription Plans",
  //   child: false,
  //   icon: <FaBoxOpen size={23} />,
  // },
  // {
  //   path: "/ads",
  //   name: "Ads",
  //   child: false,
  //   icon: <FaBuysellads size={23} />,
  // },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   child: false,
  //   icon: <AiOutlineFileProtect size={23} />,
  // },
];

export { adminRoute, vendorRoute };
