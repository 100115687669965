import axios from "axios";
import SelectCategory from "components/common/Form/SelectCategory";
import SelectCity from "components/common/Form/SelectCity";
import SelectColor from "components/common/Form/SelectColor";
import SelectCountry from "components/common/Form/SelectCountry";
import SelectGender from "components/common/Form/SelectGender";
import SelectNationality from "components/common/Form/SelectNationality";
import SelectPlayerPosition from "components/common/Form/SelectPlayerPosition";
import SelectSize from "components/common/Form/SelectSize";
import SelectSubCategory from "components/common/Form/SelectSubCategory";
import ListLoading from "components/common/LoadingScreen/ListLoading";
import { apiCall } from "helpers/apiCalls";
import { put } from "helpers/apiConfig";
import { post } from "helpers/apiConfig";
import { get } from "helpers/apiConfig";

import { getToast } from "modules/common/toast";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { FaBoxOpen, FaListAlt, FaTags, FaUserPlus } from "react-icons/fa";
import { MdDelete, MdRemove } from "react-icons/md";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { uploadImageToAzure } from "utils/blobUtils";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";

const CouponForm = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState();
  const [formError, setFormError] = useState({});
  const [getData, pageNumber] = useOutletContext();
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (e, action) => {
    let newFormData = {};
    let value;

    if (action && action.name) {
      newFormData = {
        [action.name]: e ? e.value : null,
        // [action.name + "_ref"]: e?.label,
      };
    } else if (e && e.target) {
      const { name, files, value: inputValue } = e.target;
      value = inputValue;

      newFormData = {
        ...formData,
        [name]: value,
      };
    } else {
      console.error("Unexpected input format:", e, action);
    }

    setFormData({ ...formData, ...newFormData });
  };

  const handleSubcategories = (e, action) => {
    const { name, value } = e.target;

    console.log("sub", value);

    setFormData({ ...formData, subcategory: value });
  };

  const getDataForm = async () => {
    setLoading(true);
    if (id) {
      try{
        let data = await get("/admin/coupon/" + id);


        console.log("=>>>", data?.data?.data);
        

      const updatedSubCategories =
        data?.data?.data?.subcategoryDetails?.map((category) => ({
          label: category.name,
          value: category._id,
        })) || [];

      setFormData({
        ...formData,
        couponName: data.data?.data?.couponName,
        description: data.data?.data?.description,
        couponPercentage: data.data?.data?.couponPercentage,
        maxDiscount: data.data?.data?.maxDiscount,
        category: data.data?.data?.category,
        subcategory: updatedSubCategories,
        minCartValue: data.data?.data?.minCartValue,
      });
      }catch(e) {
        console.error("Error fetching data", e);
        getToast("Error fetching data", "error");
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataForm();
  }, [id]);

  const handleSubmit = async () => {
    setLoading(true);

    const data = new FormData();
    // data.append("couponName", formData?.couponName);
    // data.append("description", formData?.description);
    // data.append("couponPercentage", formData?.couponPercentage);
    // data.append("maxDiscount", formData?.maxDiscount);
    // data.append("category", formData?.category);
    // data.append("subcategory", formData?.subcategory);
    // data.append("minCartValue", formData?.minCartValue);
    for (const key in formData) {
      if (key === "subcategory" && Array.isArray(formData[key])) {
        const subCategoriesStr = formData[key]
          .map((sub) => sub.value)
          .join(",");
        data.append("subcategory", subCategoriesStr);
      } else {
        data.append(key, formData[key]);
      }
    }

    axios({
      method: id ? "put" : "post",
      url: id ? "/admin/coupon/" + id : "/admin/coupons",
      data: data,
    })
      .then((res) => {
        console.log("success", res?.data);

        if (res.data.success === true) {
          setFormData({});
          getData(pageNumber);
          getToast(res.data.message, "success");
          navigate(-1);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data.message) {
          getToast(error.response.data.message, "error");

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          getToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Offcanvas
        show
        onHide={() => navigate(-1)}
        placement="end"
        style={{ minWidth: "70%" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <FaTags size={25} className="me-2" /> {id ? "Edit" : "Add"} Coupon
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!loading ? (
            <Form>
              <Row>
                <Col md={4} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">
                      Coupon Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="couponName"
                      onChange={handleFieldChange}
                      value={formData?.couponName}
                      style={{ border: "1px solid #ccc" }}
                      placeholder="Enter Coupon Name"
                      isInvalid={!!formError.couponName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.couponName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">
                      Coupon Percentage
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="couponPercentage"
                      onChange={handleFieldChange}
                      value={formData?.couponPercentage}
                      placeholder="Enter Coupon Percentage"
                      style={{ border: "1px solid #ccc" }}
                      isInvalid={!!formError.couponPercentage}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.couponPercentage}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">
                      Min. Cart Value
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="minCartValue"
                      onChange={handleFieldChange}
                      value={formData?.minCartValue}
                      placeholder="Enter Min. Cart Value"
                      style={{ border: "1px solid #ccc" }}
                      isInvalid={!!formError.minCartValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.minCartValue}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">
                      Max Discount
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="maxDiscount"
                      onChange={handleFieldChange}
                      value={formData?.maxDiscount}
                      style={{ border: "1px solid #ccc" }}
                      placeholder="Enter Max Discount"
                      isInvalid={!!formError.maxDiscount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.maxDiscount}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={8} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">
                      Description
                    </Form.Label>
                    <Form.Control
                      className="custom-input"
                      placeholder="Enter Description"
                      type="text"
                      name="description"
                      value={formData?.description}
                      onChange={handleFieldChange}
                      isInvalid={!!formError.description}
                      as="textarea"
                      style={{ border: "1px solid #ccc" }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">
                      Main Category
                    </Form.Label>
                    <SelectCategory
                      handleFieldChange={handleFieldChange}
                      name="category"
                      placeholder="Select Category"
                      value={formData?.category}
                      error={formError?.category}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.category}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">
                      Sub Categories
                    </Form.Label>
                    <SelectSubCategory
                      handleFieldChange={handleSubcategories}
                      name="subcategory"
                      value={formData?.subcategory ?? []}
                      placeholder="Select Subcategories"
                      error={formError?.subcategory}
                      style={{ border: "1px solid #ccc" }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.subcategory}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex mt-3">
                <Button
                  size="md"
                  className="ms-auto"
                  variant={id ? "primary" : "success"}
                  onClick={handleSubmit}
                >
                  {id ? "Update" : "Save"}
                </Button>
              </div>
            </Form>
          ) : (
            <ListLoading />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CouponForm;
