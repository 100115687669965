import axios from "axios";
import SelectCountry from "components/common/Form/SelectCountry";
import ListLoading from "components/common/LoadingScreen/ListLoading";
import { apiCall } from "helpers/apiCalls";
import { cityFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import { getToast } from "modules/common/toast";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import { ChromePicker } from "react-color";
import { FaCity } from "react-icons/fa";
import { GiPencilRuler } from "react-icons/gi";
import { MdCategory, MdColorLens } from "react-icons/md";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { uploadImageToAzure } from "utils/blobUtils";
import { removeImageFromAzure } from "utils/blobUtils";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";

const ColorForm = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState();
  const [formError, setFormError] = useState({});
  const [getData, pageNumber] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [save, setSave] = useState(false);

  const [imagePreview, setImagePreview] = useState("");
  const [image, setImage] = useState("");

  const [editingData, setEditingData] = useState();

  const handleFieldChange = (e, action) => {
    let newFormData = "";
    if (action) {
      newFormData = {
        [action.name]: e ? e.value : null,
        [action.name + "_ref"]: e,
      };
    } else {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    }
    setFormData({ ...formData, ...newFormData });
  };

  const getDataForm = async () => {
    setLoading(true);

    if (id) {
      let data = await apiCall({
        url: "/admin/colors/" + id,
      });

      console.log("data: " + JSON.stringify(data));

      if (data?.success) {
        setFormData({
          // size: data.da,
          name: data?.color?.name,
          hex: data?.color?.hex,
        });
      }

      setEditingData(data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataForm();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError({});

    setSave(true);

    const data = new FormData();
    data.append("name", formData?.name);
    data.append("hex", formData?.hex);

    axios({
      method: id ? "put" : "post",
      url: id ? "/admin/colors/" + id : "/admin/colors",
      data: data,
    })
      .then((res) => {
        if (res.data.success === true) {
          setFormData({});
          getData(pageNumber);
          getToast(res.data.message, "success");
          navigate(-1);
        }
        setSave(false);
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data.message) {
          getToast(error.response.data.message, "error");

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          getToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
        setSave(false);
      });
  };

  const handleColorChange = (newColor) => {
    setFormData({ ...formData, hex: newColor.hex });
  };

  return (
    <>
      <Offcanvas
        show
        onHide={() => navigate(-1)}
        placement="end"
        style={{ minWidth: "50vh" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <MdColorLens size={25} className="me-2" /> {id ? "Edit" : "Add"}{" "}
            Size
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!loading ? (
            <Form>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">Color Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleFieldChange}
                      value={formData?.name}
                      placeholder="Enter Color Name"
                      isInvalid={!!formError?.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">Color Hex</Form.Label>
                    <ChromePicker
                      color={formData?.hex}
                      onChange={handleColorChange}
                    />
                    <Form.Control
                      type="text"
                      name="hex"
                      value={formData?.hex}
                      readOnly
                      className="mt-2"
                      isInvalid={!!formError.hex}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.hex}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">Color Hex</Form.Label>
                    <Form.Control
                      type="text"
                      name="hex"
                      onChange={handleFieldChange}
                      value={formData?.hex}
                      placeholder="Enter Color Hex"
                      isInvalid={!!formError?.hex}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.hex}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex mt-3">
                <Button
                  size="md"
                  className="ms-auto"
                  variant={id ? "primary" : "success"}
                  onClick={handleSubmit}
                >
                  {save ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="m-0 ms-2"
                    />
                  ) : id ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Form>
          ) : (
            <ListLoading />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ColorForm;
