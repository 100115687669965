import { React } from "react";

import axios from "axios";

import AsyncSelect from "react-select/async";

import PropTypes from "prop-types";

const SelectPlayerPosition = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
}) => {
  const setItems = async (value) => {
    let response = await axios.get("player/positions", {
      params: { name: value },
    });
    let data = response && response.data ? response.data.data : [];
    return data.map((item) => ({
      label: item,
      value: keyName ? item[keyName] : item,
    }));
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={setItems}
        defaultOptions
        isClearable
        name={name ? name : "player_position"}
        value={value}
        placeholder={placeholder ?? "Select Position"}
        onChange={handleFieldChange}
        className={error && "form-control ps-0 py-0 is-invalid"}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          control: (provided) => ({
            ...provided,
            height: 42,
            minHeight: 42,
            ...style,
          }),
        }}
      ></AsyncSelect>
    </>
  );
};

SelectPlayerPosition.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectPlayerPosition;
