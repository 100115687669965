import axios from "axios";
import { baseURL } from "./apiConfig";

export const apiCall = ({
  url,
  addIitemToStore,
  storeKey,
  onSessionOut,
  params = {},
  paramsSerializer,
}) => {
  return new Promise((resolve, reject) => {

    console.log('params',params, url);
    axios
      .get(baseURL + url, {
        params: params,
        ...(paramsSerializer ? { paramsSerializer: paramsSerializer } : null),
      })
      .then((response) => {
        if (response.data.success === true) {
          addIitemToStore && addIitemToStore(storeKey, response.data.data);
          console.log(response?.data?.data);
          resolve(response?.data);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          onSessionOut
        ) {      
          onSessionOut();
        }
        reject(error);
      });
  });
};
