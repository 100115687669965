import SelectNationality from "components/common/Form/SelectNationality";
import Loading from "components/common/LoadingScreen/Loading";
import FilterButton from "components/common/filter/FilterButton";
import { apiCall } from "helpers/apiCalls";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import removeRefData from "helpers/removeRefData";
import TablePageination from "modules/common/TablePageination";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Image,
  Modal,
  // Pagination,
  Table,
} from "react-bootstrap";
import { FaEdit, FaRegFileExcel, FaUserEdit } from "react-icons/fa";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { Outlet, useNavigate } from "react-router";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import "./CouponTable.scss";
import axios from "axios";
import { getToast } from "modules/common/toast";
import { utils, write } from "xlsx";
import { saveAs } from "file-saver";
import { API } from "constants/apis";
import { get } from "helpers/apiConfig";
import { productImageURL } from "helpers/apiConfig";
import { remove } from "helpers/apiConfig";
import { Pagination } from "antd";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";
import { Modal as ConfirmDialog } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const CouponTable = () => {
  const [agentsList, setAgentsList] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();

  const [showSizeDetails, setSizeDetails] = useState(null);
  const [sizesModalVisible, setSizesModalVisible] = useState(false);
  const [selectedProductSizes, setSelectedProductSizes] = useState([]);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(2);

  const allQueryParams = Object.fromEntries([...queryParams]);
  const startIndex = 0;

  const [expanded, setExpanded] = useState({});

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  const filterItems = queryParams.get("filters");

  const [data, setData] = useState();
  const getData = async (page = 1) => {
    setIsLoading(true);
    const url = `/admin/coupons`;

    try {
      const data = await get(url);

      setData(data?.data);
      // setPageSize(data.data.totalPages);
      // setPageNumber(data?.data?.currentPage);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, filterItems]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      getData(1);
      setPageNumber(1);
    }
  };

  const handleDelete = (id) => {
    ConfirmDialog.confirm({
      title: "Are you sure you want to delete this Coupon?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await remove(`/admin/coupon/${id}`);
          if (res.data.success) {
            getToast("Coupon deleted successfully", "success");
            getData(pageNumber)
          }
        } catch (error) {
          console.error("Error deleting Coupnon:", error);
        }
      },
    });
  };

  const handleEdit = (id) => {
    navigate({
      pathname: `/coupon/edit/${id}`,
      search: `?${createSearchParams({
        ...allQueryParams,
      })}`,
    });
  };

  const handleViewSizes = (sizes, val) => {
    setSelectedProductSizes(sizes);
    setSizeDetails(val);
    setSizesModalVisible(true);
  };

  const handleCloseSizesModal = () => {
    setSizesModalVisible(false);
    setSizeDetails(null);
  };

  const handlePageChange = (page, pageSize) => {
    setPageNumber(page);
    getData(page);
  };

  return (
    <>
      <Outlet context={[getData, pageNumber]} />
      {!isLoading ? (
        <Card className="rounded-0 border-0 h-100 w-100 d-inline-block overflow-auto">
          <Card.Header className="p-2">
            <div className="d-flex justify-content-between">
              <span>
                {/* <FilterButton
                  className="me-1 d-flex justify-content-end border"
                  filters={filters}
                  url="agents"
                  variant="light"
                  showText
                /> */}
                <span className="search-box">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="border"
                    value={searchValue}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterKeyPress}
                  />
                  <FaSearch className="search-icon" />
                </span>
              </span>
              <span className="">
                <Button
                  className="rounded-0"
                  size="sm"
                  as={Link}
                  to={`add?${createSearchParams({
                    ...allQueryParams,
                  })}`}
                >
                  <MdAdd size={18} />
                </Button>
              </span>
            </div>
          </Card.Header>
          <Card.Body className="p-0 h-100 d-flex flex-column border-top">
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Coupon Name</th>
                  <th>Description</th>
                  <th>Coupon Percentage</th>
                  <th>Max Discount</th>
                  {/* <th>Validity</th> */}
                  <th>Category</th>
                  <th>Subcategory</th>
                  <th>Min Cart Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.length > 0 ? (
                  data?.data?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?._id}</td>
                        <td>{item?.couponName}</td>
                        <td>{item?.description}</td>
                        <td>{item?.couponPercentage}</td>
                        <td>{item?.maxDiscount}</td>
                        {/* <td>{item?.validity?.toString()}</td>{" "} */}
                        {/* Adjusted for Date format */}
                        <td>{item?.categoryDetails?.name}</td>
                        <td>
                          {item.subcategoryDetails?.map((subcategory) => (
                            <div key={subcategory?._id}>
                              {subcategory?.name},{"\n"}
                            </div>
                          ))}
                        </td>
                        <td>{item?.minCartValue}</td>
                        <td>
                          <div className="d-flex flex-row h-100 align-items-center">
                            <Button
                              className="fixed-view-button me-2"
                              size="sm"
                              type="link"
                              onClick={() => handleEdit(item?._id)}
                            >
                              Edit
                            </Button>
                            <Button
                              className="fixed-view-button btn-danger"
                              size="sm"
                              type="link"
                              onClick={() => handleDelete(item?._id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={11}>
                      <span className="d-flex justify-content-center">
                        No Data Found &#x1F609;
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>

          <Card.Footer className="border p-0 mt-auto"></Card.Footer>
        </Card>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CouponTable;
