import SelectNationality from "components/common/Form/SelectNationality";
import Loading from "components/common/LoadingScreen/Loading";
import FilterButton from "components/common/filter/FilterButton";
import { apiCall } from "helpers/apiCalls";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import removeRefData from "helpers/removeRefData";
import TablePageination from "modules/common/TablePageination";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Image,
  Modal,
  // Pagination,
  Table,
} from "react-bootstrap";
import { FaEdit, FaRegFileExcel, FaUserEdit } from "react-icons/fa";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { Outlet, useNavigate } from "react-router";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import "./ProductsTable.scss";
import axios from "axios";
import { getToast } from "modules/common/toast";
import { utils, write } from "xlsx";
import { saveAs } from "file-saver";
import { API } from "constants/apis";
import { get } from "helpers/apiConfig";
import { productImageURL } from "helpers/apiConfig";
import { remove } from "helpers/apiConfig";
import { Pagination } from "antd";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";
import { Modal as ConfirmDialog } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getAdminRole } from "utils/adminUtils";

const ProdductsTable = () => {
  const [agentsList, setAgentsList] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();

  const role = getAdminRole();

  const [showSizeDetails, setSizeDetails] = useState(null);
  const [sizesModalVisible, setSizesModalVisible] = useState(false);
  const [selectedProductSizes, setSelectedProductSizes] = useState([]);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(2);

  const [vendorModal, setVendorModal] = useState(null);

  const allQueryParams = Object.fromEntries([...queryParams]);
  const startIndex = 0;

  const [expanded, setExpanded] = useState({});

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  const filterItems = queryParams.get("filters");

  const [data, setData] = useState();
  const getData = async (page = 1) => {
    console.log("called");

    setIsLoading(true);
    const url = `/panel/products?search=${searchValue}&page=${page}`;

    try {
      const data = await get(url);
      console.log("data", data?.data);

      setData(data?.data);
      setPageSize(data?.data?.totalPages);
      setPageNumber(data?.data?.currentPage);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, filterItems]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      getData(1);
      setPageNumber(1);
    }
  };

  const handleDelete = (id) => {
    ConfirmDialog.confirm({
      title: "Are you sure you want to delete this product?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await remove(`/admin/product/${id}`);
          if (res.data.success) {
            getToast("Product deleted successfully", "success");
            const filteredProducts = data?.products?.filter(
              (product) => product._id !== id
            );
            setData({ ...data, products: filteredProducts });
            // getData(page);
          }
        } catch (error) {
          console.error("Error deleting product:", error);
        }
      },
    });
  };

  const handleEdit = (id) => {
    navigate({
      pathname: `/products/edit/${id}`,
      search: `?${createSearchParams({
        ...allQueryParams,
      })}`,
    });
  };

  const handleViewSizes = (sizes, val) => {
    setSelectedProductSizes(sizes);
    setSizeDetails(val);
    setSizesModalVisible(true);
  };

  const handleCloseSizesModal = () => {
    setSizesModalVisible(false);
    setSizeDetails(null);
  };

  const handlePageChange = (page, pageSize) => {
    setPageNumber(page);
    getData(page);
  };

  const handleViewVendor = (value) => {
    setVendorModal(value);
  };

  return (
    <>
      <Outlet context={[getData, pageNumber]} />
      {!isLoading ? (
        <Card className="rounded-0 border-0 h-100 w-100 d-inline-block overflow-auto">
          <Card.Header className="p-2">
            <div className="d-flex justify-content-between">
              <span>
                {/* <FilterButton
                  className="me-1 d-flex justify-content-end border"
                  filters={filters}
                  url="agents"
                  variant="light"
                  showText
                /> */}
                <span className="search-box">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="border"
                    value={searchValue}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterKeyPress}
                  />
                  <FaSearch className="search-icon" />
                </span>
              </span>
              {role == "vendor" && (
                <span className="">
                  <Button
                    className="rounded-0"
                    size="sm"
                    as={Link}
                    to={`add?${createSearchParams({
                      ...allQueryParams,
                    })}`}
                  >
                    <MdAdd size={18} />
                  </Button>
                </span>
              )}
            </div>
          </Card.Header>
          <Card.Body className="p-0 h-100 d-flex flex-column border-top">
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Brand</th>
                  <th>Material</th>
                  <th>Expected Delivery</th>
                  <th>Color</th>
                  <th>Image</th>
                  <th className="text-nowrap">Main Categories</th>
                  <th className="text-nowrap">Sub Categories</th>
                  <th>Sizes</th>
                  <th>Total Quantity</th>
                  {role == "admin" && (
                    <th className="text-nowrap">Vendor Details</th>
                  )}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.products?.length > 0 ? (
                  data?.products?.map((product, index) => {
                    const totalQuantity = product?.sizes?.reduce(
                      (acc, size) => acc + size?.quantity,
                      0
                    );
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <span
                            style={{
                              backgroundColor: "yellow",
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            {product?.productId}
                          </span>
                        </td>
                        <td
                          style={{
                            maxWidth: "200px",
                            minWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {product.title}
                        </td>
                        <td
                          style={{
                            maxWidth: "400px",
                            minWidth: "400px",
                            flexDirection: "row",
                          }}
                        >
                          {expanded[product._id]
                            ? product.description
                            : product.description?.slice(0, 35) + "... "}
                          {product?.description?.length > 35 && (
                            <button
                              className="btn btn-link p-0 text-primary"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                              onClick={() => toggleExpand(product._id)}
                            >
                              {expanded[product._id]
                                ? "Read Less"
                                : "Read More"}
                            </button>
                          )}
                        </td>

                        <td style={{ whiteSpace: "nowrap" }}>
                          {/* <img
                            src={product?.brandDetails?.logo}
                            // className="img-fluid"
                            width="20"
                            height="20"
                           className="me-2"
                            alt="Product"
                          /> */}
                          {product?.brandDetails?.brand}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {product.material}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {product.delivery === 0
                            ? "Same Day"
                            : `Within ${product.expectedDeliveryInDays} Day${
                                product.delivery > 1 ? "s" : ""
                              }`}
                        </td>

                        <td>{product.colorDetails?.name}</td>
                        <td>
                          <img
                            src={`${IMAGE_URL}${product.images[0]}`}
                            // className="img-fluid"
                            width="50"
                            height="50"
                            // style={{ objectFit: "contain" }}
                            alt="Product"
                          />
                        </td>
                        <td>{product.categoryDetails?.name}</td>
                        <td>
                          {product.subcategoryDetails?.map((subcategory) => (
                            <div key={subcategory?._id}>
                              {subcategory?.name},{"\n"}
                            </div>
                          ))}
                        </td>
                        <td>
                          <Button
                            className="fixed-view-button"
                            size="sm"
                            type="link"
                            onClick={() => handleViewSizes(product.sizes, 0)}
                          >
                            View
                          </Button>
                        </td>

                        <td>{totalQuantity}</td>
                        {role == "admin" && (
                          <td>
                            <Button
                              className="fixed-view-button"
                              size="sm"
                              type="link"
                              onClick={() =>
                                handleViewVendor(product?.vendorDetails)
                              }
                            >
                              View
                            </Button>
                          </td>
                        )}
                        <td>
                          <div className="d-flex flex-row h-100 align-items-center">
                            <Button
                              className="fixed-view-button me-2"
                              size="sm"
                              type="link"
                              onClick={() => handleEdit(product?._id)}
                            >
                              Edit
                            </Button>
                            <Button
                              className="fixed-view-button btn-danger"
                              size="sm"
                              type="link"
                              onClick={() => handleDelete(product?._id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={14}>
                      <span className="d-flex justify-content-center">
                        No Data Found &#x1F609;
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
          {/* {(data?.products?.length >= 10 || pageNumber !== 1) && (
            <Pagination
              current={pageNumber}
              pageSize={pageSize}
              total={pageSize + 1}
              onChange={handlePageChange}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            />
          )} */}
          <Card.Footer className="border p-0 mt-auto"></Card.Footer>
        </Card>
      ) : (
        <Loading />
      )}

      <Modal
        title="Product Sizes"
        show={sizesModalVisible} // Use `show` instead of `visible`
        onHide={handleCloseSizesModal}
        size={showSizeDetails === 1 ? "lg" : "md"} // Adjust size dynamically
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Sizes</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto" }}>
          <Table>
            <thead>
              <tr>
                <th>Size</th>
                <th>Sale Price</th>
                <th>Quantity</th>
                {showSizeDetails === 1 && (
                  <>
                    <th>Discount %</th>
                    <th>Discount £</th>
                    <th>After Discount</th>
                    <th>Admin Fee</th>
                    <th>Net Amount</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {selectedProductSizes.map((size, index) => (
                <tr key={index}>
                  <td>{size.size}</td>
                  <td>{size.price}</td>
                  <td>{size.quantity}</td>
                  {showSizeDetails === 1 && (
                    <>
                      <td>
                        {((size.discount * 100) / size.price) % 1 === 0
                          ? (size.discount * 100) / size.price + " %"
                          : ((size.discount * 100) / size.price).toFixed(2) +
                            " %"}
                      </td>
                      <td>{size.discount}</td>
                      <td>{size.price - size.discount}</td>
                      <td>
                        {(size.price * 0.2)?.toString()?.slice(0, 5)} - (20%)
                      </td>
                      <td>{(size.price * 0.8).toString()?.slice(0, 5)}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSizesModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <MyModal
        show={vendorModal != null ? true : false}
        handleClose={() => setVendorModal(null)}
        data={vendorModal}
      />
    </>
  );
};

export default ProdductsTable;

const StatusButton = ({ bg, onPress }) => {
  return (
    <Button
      onClick={onPress}
      variant={bg}
      style={{
        borderWidth: 0,
        fontSize: 11,
        width: 80,
      }}
    >
      {bg == "success" ? "Activate" : "Deactivate"}
    </Button>
  );
};

const MyModal = ({ show, handleClose, data }) => {
  return (
    <Modal className="field-modal-container" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="field-modal-table table table-bordered">
          <tbody>
            <tr>
              <th width={"50%"}>Name</th>
              <td>{data?.first_name + " " + data?.last_name}</td>
            </tr>

            <tr>
              <th>Phone Number</th>
              <td>{data?.phone_number ?? "--"}</td>
            </tr>

            <tr>
              <th>Email</th>
              <td>{data?.email ?? "--"}</td>
            </tr>
            <tr>
              <th>Company Name</th>
              <td>{data?.companyName ?? "--"}</td>
            </tr>
            <tr>
              <th>Business Name</th>
              <td>{data?.businessName ?? "--"}</td>
            </tr>
            <tr>
              <th>Registration Number</th>
              <td>{data?.registrationNumber ?? "--"}</td>
            </tr>
            <tr>
              <th>Registration Date</th>
              <td>{data?.registrationDate?.slice(0, 10) ?? "--"}</td>
            </tr>
            <tr>
              <th>International Vendor</th>
              <td>{data?.isInternationalVendor ? "✅ Yes" : "❌ No"}</td>
            </tr>
            <tr>
              <th>Document Type</th>
              <td>
                
                {data?.verified_document_details
                  ? data?.verified_document_type
                  : "-"}
              </td>
            </tr>
            <tr>
              <th>Document Number</th>
              <td>{data?.verified_document_details ?? "--"}</td>
            </tr>
            <tr>
              <th>Minimum Order Amount For Commission</th>
              <td>{data?.minimumOrderAmountForCommission ?? "--"}</td>
            </tr>
            <tr>
              <th>Commission Percentage</th>
              <td>{data?.commissionPercentage ?? "--"} %</td>
            </tr>
          </tbody>
        </table>
        <div style={{ height: 30 }} />
      </Modal.Body>
    </Modal>
  );
};
