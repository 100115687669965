import axios from "axios";
import SelectCountry from "components/common/Form/SelectCountry";
import ListLoading from "components/common/LoadingScreen/ListLoading";
import { apiCall } from "helpers/apiCalls";
import { cityFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import { getToast } from "modules/common/toast";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import { FaCity } from "react-icons/fa";
import { MdCategory } from "react-icons/md";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { uploadImageToAzure } from "utils/blobUtils";
import { removeImageFromAzure } from "utils/blobUtils";
const IMAGE_URL = process.env.REACT_APP_BLOB_IMAGE_BASE_URL || "";

const CategoryForm = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState();
  const [formError, setFormError] = useState({});
  const [getData, pageNumber] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [save, setSave] = useState(false);

  const [imagePreview, setImagePreview] = useState("");
  const [image, setImage] = useState("");

  const [editingData, setEditingData] = useState();

  const handleFieldChange = (e, action) => {
    let newFormData = "";
    if (action) {
      newFormData = {
        [action.name]: e ? e.value : null,
        [action.name + "_ref"]: e,
      };
    } else {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    }
    setFormData({ ...formData, ...newFormData });
  };

  const getDataForm = async () => {
    setLoading(true);

    if (id) {
      let data = await apiCall({
        url: "/admin/category/" + id,
      });

      console.log("data", data?.category);

      if (data?.success) {
        setFormData({
          name: data.category.name,
          description: data.category.description,
        });
      }

      setImagePreview(data?.category?.image);

      setEditingData(data?.category);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataForm();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError({});
    let newFormData = setNewFormData(removeRefData(formData));
    setSave(true);

    console.log(formData);

    const data = new FormData();
    data.append("name", formData?.name);
    data.append("description", formData.description);
    if (image) {
      if (editingData) {
        await removeImageFromAzure(
          IMAGE_URL + editingData?.image,
          "images/category-images"
        );
      }

      const url = await uploadImageToAzure(image, "images/category-images");
      const blobName = url.split(`/images/`)[1];
      console.log("blobName, ", blobName);
      data.append("image", blobName);
    }

    axios({
      method: id ? "put" : "post",
      url: id ? "/admin/category/" + id : "/admin/category",
      data: data,
    })
      .then((res) => {
        if (res.data.success === true) {
          setFormData({});
          getData(pageNumber);
          getToast(res.data.message, "success");
          navigate(-1);
        }
        setSave(false);
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data.message) {
          getToast(error.response.data.message, "error");

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          getToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
        setSave(false);
      });
  };

  return (
    <>
      <Offcanvas
        show
        onHide={() => navigate(-1)}
        placement="end"
        style={{ minWidth: "50vh" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex align-items-center">
            <MdCategory size={25} className="me-2" /> {id ? "Edit" : "Add"}{" "}
            Category
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!loading ? (
            <Form>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleFieldChange}
                      value={formData?.name}
                      placeholder="Enter Name"
                      isInvalid={!!formError?.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput0"
                  >
                    <Form.Label className="require-data">
                      Description
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      onChange={handleFieldChange}
                      value={formData?.description}
                      placeholder="Enter Arabic Name"
                      isInvalid={!!formError?.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlSelect0"
                  >
                    <Form.Label className="require-data">Image</Form.Label>
                    <Form.Control
                      type="file"
                      name="image"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setImage(file);
                          setImagePreview(URL.createObjectURL(file));
                        }
                      }}
                      isInvalid={!!formError.sizeChart}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.sizeChart}
                    </Form.Control.Feedback>
                    <div className="mt-3">
                      {imagePreview && (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={
                              imagePreview?.includes("http")
                                ? imagePreview
                                : IMAGE_URL + imagePreview
                            }
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setImagePreview(null);
                              setImagePreview(null);
                            }}
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-5px",
                              background: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            ×
                          </button>
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex mt-3">
                <Button
                  size="md"
                  className="ms-auto"
                  variant={id ? "primary" : "success"}
                  onClick={handleSubmit}
                >
                  {save ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="m-0 ms-2"
                    />
                  ) : id ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Form>
          ) : (
            <ListLoading />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CategoryForm;
